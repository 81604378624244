import usePQRoutes from "@/commons/hooks/usePQRoutes";
import { RoutesKeys } from "@/commons/keys";
import includes from "lodash-es/includes";
class RetailerUtility {
  static optimizedLogo(retailer, format) {
    if (
      retailer.logoWebP &&
      includes(retailer.logoWebPSupportedFormats, format)
    ) {
      return retailer.logoWebP.getFormat(format);
    }
    return retailer.logo.getFormat(format);
  }

  static getUrlLocal(citySlug, sectionSlug, retailerSlug) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.RETAILER_LOCAL, {
      city: citySlug,
      section: sectionSlug,
      slug: retailerSlug,
    });
  }

  static getUrlNational(sectionSlug, retailerSlug) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.RETAILER, {
      section: sectionSlug,
      slug: retailerSlug,
    });
  }

  static getUrlLocalAmp(citySlug, sectionSlug, retailerSlug) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.RETAILER_LOCAL_AMP, {
      city: citySlug,
      section: sectionSlug,
      slug: retailerSlug,
    });
  }

  static getUrlNationalAmp(sectionSlug, retailerSlug) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.RETAILER_AMP, {
      section: sectionSlug,
      slug: retailerSlug,
    });
  }
}

export default RetailerUtility;
