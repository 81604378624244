import {
  PQAutocomplete,
  PQAutocompleteVertical,
  PQButtonAutocomplete,
  PQTextFieldLabelHidden,
} from "@/commons/ui/mui/UtilityAutocomplete";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AutocompleteItem from "@/commons/ui/components/autocomplete-item/AutocompleteItem";
import Button from "@mui/material/Button";
import { CookieKeys } from "@/commons/keys";
import Geolocation from "@/commons/dataset/services/Geolocation";
import GooglePlaces from "@/commons/dataset/services/GooglePlaces";
import LocalizeMe from "@/commons/dataset/services/LocalizeMe";
import LocationModel from "@/commons/dataset/models/LocationModel";
import LocationRewriteUtility from "@/commons/utilities/LocationRewriteUtility";
import LocationUtility from "@/commons/utilities/LocationUtility";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import PqConsole from "@/commons/utilities/PqConsole";
import { setCookie } from "cookies-next";
import { setUserLocation } from "@/commons/redux/actions/locationActions";
import styles from "./LocationAutocomplete.module.scss";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import { useRouter } from "next/router";

interface Props {
  vertical?: boolean;
  disabled?: boolean;
  simpleInput: any;
  required?: boolean;
  locationCallback(_: any): void;
  placeholder: string;
}

const LocationAutocomplete = ({
  vertical,
  disabled,
  simpleInput,
  required,
  locationCallback,
  placeholder,
}: Props) => {
  const { ccontext }: any = useSelector((state) => state);
  const { rStateLocationHref, rStateHeaders, rStateAppRender } = ccontext;

  const router = useRouter();
  const t = usePQTranslations();
  const timeout = useRef(null);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const storeLocation = useSelector((state: any) => state.location);
  let { realLocation } = storeLocation;
  realLocation = realLocation ? new LocationModel(realLocation) : null;
  const [value, setValue] = useState(realLocation?.fullAddress);
  const [simpleInputLocation, setSimpleInputLocation] = useState<any>();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const urlLocation = LocationUtility.getUrlLocation();

  useEffect(() => {
    if (locationCallback) {
      locationCallback(simpleInputLocation);
    }
  }, [simpleInputLocation, locationCallback]);

  useEffect(() => {
    setValue(realLocation?.fullAddress);
  }, [storeLocation]);

  const findLocationService = useCallback(async (value) => {
    try {
      const results = await GooglePlaces.findPlace(value);
      if (results) {
        setOptions(results || []);
      } else {
        PqConsole.error("GooglePlaces [findPlace] not work");
      }
    } catch (error) {
      PqConsole.error("GooglePlaces [findPlace] not work", error);
    }
  }, []);

  const findLocation = useCallback(
    (evt, newValue) => {
      const valueInput = evt?.target?.value || newValue;
      if (valueInput == value || valueInput == "") return;
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        findLocationService(valueInput);
      }, 350);
    },
    [findLocationService, value],
  );

  const localizeMe = useCallback(async () => {
    // TODO Refactoring: see NoPreciseLocation localizeMe
    try {
      setLoadingBtn(true);
      const position = await LocalizeMe.localize(rStateHeaders);

      if (position) {
        const placeDetails = {
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        };

        const full_address = await GooglePlaces.placeDetailsByCoords(
          placeDetails,
        );
        setValue(full_address);

        const geolocation = new Geolocation();
        const location = await geolocation.byLatLon(
          position.coords.latitude,
          position.coords.longitude,
          { full_address },
        );

        if (location) {
          if (simpleInput) {
            setSimpleInputLocation(location);
          } else {
            dispatch(setUserLocation(location));
            // disable encoding for location2 encodes by geolocation
            setCookie(CookieKeys.LOCATION2, location.location2, {
              encode: String,
            });

            LocationRewriteUtility.reloadOrRewriteUrlToNational(
              rStateAppRender,
              router,
              rStateLocationHref,
              urlLocation,
            );
          }
        } else {
          PqConsole.error("Geolocation service byLatLon not work");
        }
      } else {
        PqConsole.error("LocalizeMe not work");
      }
    } catch (error) {
      PqConsole.error("LocalizeMe not work", error);
    } finally {
      setLoadingBtn(false);
    }
  }, []);

  const selectedLocation = useCallback(async (event, location) => {
    setValue(location?.description);

    if (!location) {
      if (simpleInput) {
        setSimpleInputLocation(null);
      }
      return;
    }

    try {
      const locationByUser = await GooglePlaces.placeDetails(location);
      if (locationByUser) {
        setLocation(locationByUser);
      } else {
        PqConsole.error("GooglePlaces [placeDetails] not work");
      }
    } catch (error) {
      PqConsole.error("GooglePlaces [placeDetails] not work", error);
    }
  }, []);

  const setLocation = useCallback(
    async (locationByUser) => {
      try {
        const params = {
          latitude: locationByUser.latitude,
          longitude: locationByUser.longitude,
          full_address: locationByUser.full_address,
          short_address: locationByUser.short_address,
          zip_code: locationByUser.zip_code,
        };
        const geolocation = new Geolocation();
        const location = await geolocation.byUser(params);
        if (location) {
          if (simpleInput) {
            setSimpleInputLocation(location);
          } else {
            dispatch(setUserLocation(location));
            // disable encoding for location2 encodes by geolocation
            setCookie(CookieKeys.LOCATION2, location.location2, {
              encode: String,
            });
            
            LocationRewriteUtility.reloadOrRewriteUrlToNational(
              rStateAppRender,
              router,
              rStateLocationHref,
              urlLocation,
            );
          }
        } else {
          PqConsole.error("Geolocation service byUser not work");
        }
      } catch (error) {
        PqConsole.error("Geolocation service byUser not work", error);
      }
    },
    [dispatch, rStateLocationHref, router, simpleInput, urlLocation],
  );

  const renderAutocompleteItem = useCallback((props, option) => {
    const description = (
      <div>
        {`${option.structured_formatting.main_text} - `}
        <small>{option.structured_formatting.secondary_text}</small>
      </div>
    );
    const key = `autocompleteItem${option.description}`;
    return (
      <li {...props} key={key} style={{ width: "100%" }}>
        <AutocompleteItem
          value={description}
          icon={"map-marker-alt"}
          blue={true}
        />
      </li>
    );
  }, []);

  return vertical ? (
    <div
      className={`${styles.locationAutocomplete} ${
        vertical && styles.locationAutocompleteVertical
      }`}
    >
      <PQAutocompleteVertical
        value={
          simpleInput && !simpleInputLocation?.default
            ? simpleInputLocation?.fullAddress ||
              simpleInputLocation?.shortAddress ||
              simpleInputLocation?.description ||
              ""
            : ""
        }
        freeSolo
        noOptionsText={t("location.not_found")}
        onInputChange={findLocation}
        onChange={selectedLocation}
        size="small"
        className={styles.locationAutocompleteInput}
        id="locationSearch"
        getOptionLabel={(option: any) =>
          typeof option == "string" ? option : option?.description
        }
        renderOption={renderAutocompleteItem}
        filterOptions={(x) => x}
        options={options}
        renderInput={(params) => (
          <PQTextFieldLabelHidden
            required={required}
            label={
              realLocation.default || simpleInput
                ? placeholder || t("location.autocomplete_placeholder")
                : value
            }
            {...params}
          />
        )}
      />
      <Button
        disabled={disabled}
        color="secondary"
        className={`${styles.locationAutocompleteButton} ${
          vertical && styles.locationAutocompleteButtonVertical
        }`}
        variant="contained"
        onClick={localizeMe}
      >
        {loadingBtn && (
          <PQIcon
            classesAdd={[styles.locationAutocompleteButtonIcon]}
            icon={"circle-o-notch"}
            rotate={true}
          />
        )}
        {!loadingBtn && (
          <span className={styles.locationAutocompleteButtonLabelVertical}>
            {t("location.use_my_position")}
          </span>
        )}
      </Button>
    </div>
  ) : (
    <div
      className={`${styles.locationAutocomplete} ${
        vertical && styles.locationAutocompleteVertical
      }`}
    >
      <PQAutocomplete
        value={
          simpleInput && !simpleInputLocation?.default
            ? simpleInputLocation?.fullAddress ||
              simpleInputLocation?.shortAddress ||
              simpleInputLocation?.description ||
              ""
            : ""
        }
        freeSolo
        noOptionsText={t("location.not_found")}
        onInputChange={findLocation}
        onChange={selectedLocation}
        size="small"
        className={styles.locationAutocompleteInput}
        id="locationSearch"
        getOptionLabel={(option: any) =>
          typeof option == "string" ? option : option?.description
        }
        renderOption={renderAutocompleteItem}
        filterOptions={(x) => x}
        options={options}
        renderInput={(params) => (
          <PQTextFieldLabelHidden
            required={required}
            label={
              realLocation?.default || simpleInput
                ? placeholder || t("location.autocomplete_placeholder")
                : value
            }
            {...params}
          />
        )}
      />
      <PQButtonAutocomplete
        disabled={disabled}
        color="secondary"
        className={styles.locationAutocompleteButton}
        variant="contained"
        onClick={localizeMe}
      >
        {!loadingBtn && (
          <PQIcon
            classesAdd={[styles.locationAutocompleteButtonIcon]}
            icon={"location-arrow"}
          />
        )}
        {loadingBtn && (
          <PQIcon
            classesAdd={[styles.locationAutocompleteButtonIcon]}
            icon={"circle-o-notch"}
            rotate={true}
          />
        )}
        <span className={styles.locationAutocompleteButtonLabel}>
          {t("location.find_me")}
        </span>
      </PQButtonAutocomplete>
    </div>
  );
};

export default LocationAutocomplete;
