import SparkMD5 from "spark-md5";

const CreateTrackingMetaUtility = (password) => {
  const date = new Date();
  const isoTimestamp = date.toISOString();
  const time = date.getTime();
  const unixTimeStamp = Math.floor(time / 1000);
  const expirationTimestamp = unixTimeStamp + 300;
  const md5security = SparkMD5.hash(`${expirationTimestamp} ${password}`, true);
  const base64security = window.btoa(md5security);
  const securityToken = base64security
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return { ts: isoTimestamp, et: expirationTimestamp, st: securityToken };
};
export default CreateTrackingMetaUtility;
