import React from "react";

import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import style from "./BreadCrumb.module.scss";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import Navigator from "@/commons/utilities/Navigator";
import { ccontextSelector } from "@/commons/redux/selectors/ccontext";
import { useSelector } from "react-redux";

export interface IBreadCrumbSection {
  url: string;
  pathSection: string;
}
interface IBreadCrumbProps {
  sections: IBreadCrumbSection[];
}

/**
TODO:
1. verifica markup
2. verifica path (vedi pagine da verificare)
 */

const BreadCrumb: React.FC<IBreadCrumbProps> = ({ sections = [] }) => {
  const { pqConfs } = useSelectorPQGlobalThis();
  const { rStateHeaders } = useSelector(ccontextSelector);

  // show breadcrumb only for desktop and tablet
  if (
    !(Navigator.isTablet(rStateHeaders) || Navigator.isDesktop(rStateHeaders))
  ) {
    return null;
  }

  return (
    <ol className={style.breadCrumbContainer}>
      <li className={style.breadCrumbItem}>
        {sections?.length > 0 && (
          // eslint-disable-next-line @next/next/no-html-link-for-pages
          <a
            href={pqConfs.frontendDomain + "/"}
            className={style.breadCrumbButton}
          >
            <PQIcon icon={"home"} />
            &nbsp;
            <PQIcon icon={"chevron-right"} />
            &nbsp;
          </a>
        )}
      </li>
      {sections?.map((section) => {
        const pathSectionCapitalize =
          section.pathSection.charAt(0).toUpperCase() +
          section.pathSection.slice(1);
        return (
          <li key={section.pathSection} className={style.breadCrumbItem}>
            {section.url ? (
              <a
                href={pqConfs.frontendDomain + section.url}
                className={style.breadCrumbButton}
              >
                {`${pathSectionCapitalize}`}
                &nbsp;
                <PQIcon icon={"chevron-right"} />
                &nbsp;
              </a>
            ) : (
              pathSectionCapitalize
            )}
          </li>
        );
      })}
    </ol>
  );
};

export default BreadCrumb;
