import usePQRoutes from "@/commons/hooks/usePQRoutes";
import { RoutesKeys } from "@/commons/keys";

class SeachUtils {
  static getUrl(key) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.SEARCH, { key: key });
  }
  static getUrlWithModel(key) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.SEARCH_MODEL, { key: key });
  }
}

export default SeachUtils;
