import React, { memo } from "react";
import style from "./MenuListDivider.module.scss";

const MenuListDivider = () => {
  return (
    <li className={style.menuListDivider}>
      <hr />
    </li>
  );
};

export default memo(MenuListDivider);
