import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styles from "./Carousel.module.scss";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={`${styles.emblaButton} ${styles.emblaButtonP}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <PQIcon icon={"chevron-left"} />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={`${styles.emblaButton} ${styles.emblaButtonN}`}
    onClick={onClick}
    disabled={!enabled}
  >
    <PQIcon icon={"chevron-right"} />
  </button>
);

const Carousel = ({ children, slidesToScroll = 4, containerClass = null }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    startIndex: 0,
    align: 0,
    slidesToScroll: slidesToScroll,
    skipSnaps: false,
    dragFree: true,
    containScroll: "trimSnaps",
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(
    (evt) => {
      evt.stopPropagation();
      embla && embla.scrollPrev();
    },
    [embla],
  );

  const scrollNext = useCallback(
    (evt) => {
      evt.stopPropagation();
      embla && embla.scrollNext();
    },
    [embla],
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <div className={styles.embla}>
      <div className={styles.emblaViewport} ref={viewportRef}>
        <div className={styles[`emblaContainer${containerClass ?? ""}`]}>
          <>
            {children}
            <div className={styles.emblaEmptySlide}></div>
          </>
        </div>
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default Carousel;
