import { Box, Stack, useTheme } from "@mui/system";
import PromoQuiCarousel, { IAheadItem } from "../promoqui-carousel";
import { Typography, useMediaQuery } from "@mui/material";

import Link from "next/link";
import MenuList from "../menu-list/MenuList";
import MenuListItem from "@/commons/ui/components/menu-list-item/MenuListItem";
import PQIcon from "../pq-icon/PQIcon";
import { RootState } from "@/commons/redux/reducers/stores";
import { RoutesKeys } from "@/commons/keys";
import style from "./CategoriesList.module.scss";
import { useGetHomeCategoriesQuery } from "generated/graphql/types";
import usePQRoutes from "@/commons/hooks/usePQRoutes";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import { useSelector } from "react-redux";

interface Props {
  carouselModeEnabled?: boolean;
  slidesToScroll?: number;
  urlLatestLeaflet?: string;
}

const CategoriesList = ({
  carouselModeEnabled = false,
  slidesToScroll = 4,
  urlLatestLeaflet = null,
}: Props) => {
  const t = usePQTranslations();
  const theme = useTheme();
  const routes = usePQRoutes();

  const { ccontext } = useSelector((state: RootState) => state);
  const { rStateIsChromeLighthouse, rStateIsBot } = ccontext;

  const bp = "lg"; // 1300 px
  let matches = useMediaQuery(theme.breakpoints.up(bp));

  // To avoid issue on carousel rendered by rendertron (using slackbot)
  if (rStateIsBot && !rStateIsChromeLighthouse) {
    matches = true;
  }

  const { data, loading } = useGetHomeCategoriesQuery({
    fetchPolicy: "cache-only",
  });

  if (loading) return null;

  // DISPLAY CATEGORIES AS LIST
  if (matches || carouselModeEnabled) {
    return (
      <MenuList>
        {urlLatestLeaflet && (
          <MenuListItem
            key="leaflets.new"
            url={urlLatestLeaflet}
            icon={<PQIcon icon="newspaper" />}
            label={t("leaflets.new")}
            color={style.pqBlue}
          />
        )}
        {data.HomePageCategories.map((category) => (
          <MenuListItem
            key={category.id}
            url={routes(RoutesKeys.OFFER, {
              slug: category.slug,
            })}
            icon={<PQIcon icon={category.icon} />}
            label={category.name}
            color={category.color}
          />
        ))}
      </MenuList>
    );
  }
  // DISPLAY CATEGORIES AS CAROUSEL
  return (
    <PromoQuiCarousel
      slidesToScroll={slidesToScroll}
      data={data.HomePageCategories}
      aheadItems={
        urlLatestLeaflet
          ? [
              {
                key: "leaflets.new",
                url: urlLatestLeaflet,
                icon: "newspaper",
                name: t("leaflets.new"),
                color: style.pqBlue,
              },
            ]
          : []
      }
      renderAheadItems={(aheadItems: IAheadItem, index?: number) => {
        return (
          <Link key={aheadItems.key} href={aheadItems.url}>
            <a title={aheadItems.name}>
              <Box marginRight={5} width={"100%"}>
                <Stack width={110}>
                  <Typography
                    textAlign="center"
                    fontSize={48}
                    color={aheadItems.color}
                  >
                    <PQIcon icon={aheadItems.icon} />
                  </Typography>
                  <Typography
                    fontWeight="bold"
                    color={"GrayText"}
                    textAlign="center"
                    fontSize={15}
                  >
                    {aheadItems.name}
                  </Typography>
                </Stack>
              </Box>
            </a>
          </Link>
        );
      }}
      renderItem={(category: any, index?: number) => {
        return (
          <Link
            key={index}
            href={routes(RoutesKeys.OFFER, {
              slug: category.slug,
            })}
          >
            <a title={category.name}>
              <Box marginRight={5} width={"100%"}>
                <Stack width={110}>
                  <Typography
                    textAlign="center"
                    fontSize={48}
                    color={category.color}
                  >
                    <PQIcon icon={category.icon} />
                  </Typography>
                  <Typography
                    fontWeight="bold"
                    color={"GrayText"}
                    textAlign="center"
                    fontSize={15}
                  >
                    {category.name}
                  </Typography>
                </Stack>
              </Box>
            </a>
          </Link>
        );
      }}
    />
  );
};

export default CategoriesList;
