import { useSelectorPQGlobalThis } from "./usePQGlobalThis";
import replace from "lodash/replace";
import PqConsole from "@/commons/utilities/PqConsole";

const replaceParams = (value, params) => {
  if (params) {
    Object.keys(params).forEach((k) => {
      value = replace(value, new RegExp(`%{${k}}`, "g"), params[k]);
    });
  }
  return value;
};

const usePQRoutes = () => {
  return (key, params = {}) => {
    const { pqConfs } = useSelectorPQGlobalThis();

    try {
      if (!pqConfs?.routes) {
        throw new Error(`PQ ROUTES: confs.routes not settings in globalThis`);
      }
      const routes = pqConfs.routes;
      const rout = routes[key];
      if (rout) {
        return replaceParams(rout, params);
      } else {
        throw new Error(`PQ ROUTES: ${key} not find`);
      }
    } catch (error) {
      PqConsole.error("usePQRoutes", error);
      return key;
    }
  };
};

export default usePQRoutes;
