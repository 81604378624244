import React from "react";
import style from "./Main.module.scss";

const Main = ({ light, children }) => {
  let mainClasses = [style.main];
  if (light) mainClasses.push(style.mainLight);
  return (
    <main className={mainClasses.join(" ")}>
      <div className={style.mainContent}>{children}</div>
    </main>
  );
};

Main.defaultProps = {
  light: false,
};

export default Main;
