import PqConsole from "@/commons/utilities/PqConsole";
class JSONUtils {
  static parse(objString) {
    try {
      const obj = JSON.parse(objString);
      return obj;
    } catch (e) {
      PqConsole.warning("parse JSON fallito!");
      return null;
    }
  }

  static stringify(obj) {
    if (typeof obj == "object") {
      return JSON.stringify(obj);
    }
    return null;
  }
}

export default JSONUtils;
