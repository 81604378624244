import {
  PQAutocomplete,
  PQButtonAutocomplete,
  PQTextFieldLabelHidden,
} from "@/commons/ui/mui/UtilityAutocomplete";
import React, { useCallback, useEffect, useRef, useState } from "react";

import AutocompleteItem from "@/commons/ui/components/autocomplete-item/AutocompleteItem";
import { EntityTypeConstants } from "@/commons/dataset/constants/EntityConstants";
import Navigator from "@/commons/utilities/Navigator";
import OfferUtils from "@/commons/utilities/OfferUtils";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import PqConsole from "@/commons/utilities/PqConsole";
import ProductUtils from "@/commons/utilities/ProductUtils";
import RetailerUtility from "@/commons/utilities/RetailerUtility";
import SearchUtils from "@/commons/utilities/SearchUtils";
import ShoppingCenterUtils from "@/commons/utilities/ShoppingCenterUtils";
import SuggestionsCookieUtils from "@/commons/utilities/SuggestionsCookieUtils";
import SuggestionsQueries from "@/commons/dataset/queries/SuggestionsQueries";
import styles from "./Searchbar.module.scss";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import { useSelector } from "react-redux";

const searchSuggestions = (value) => {
  let retailerIds = [],
    brandIds = [],
    categoryIds = [];

  value = value.trim();

  if (!value || value == "") {
    let cookieSuggestions = SuggestionsCookieUtils.get();
    if (cookieSuggestions) {
      retailerIds = cookieSuggestions[EntityTypeConstants.RETAILER]
        ? cookieSuggestions[EntityTypeConstants.RETAILER].slice(
            Math.max(
              cookieSuggestions[EntityTypeConstants.RETAILER].length - 3,
              0,
            ),
          )
        : [];
      brandIds = cookieSuggestions[EntityTypeConstants.BRAND]
        ? cookieSuggestions[EntityTypeConstants.BRAND].slice(
            Math.max(
              cookieSuggestions[EntityTypeConstants.BRAND].length - 3,
              0,
            ),
          )
        : [];
      categoryIds = cookieSuggestions[EntityTypeConstants.CATEGORY]
        ? cookieSuggestions[EntityTypeConstants.CATEGORY].slice(
            Math.max(
              cookieSuggestions[EntityTypeConstants.CATEGORY].length - 3,
              0,
            ),
          )
        : [];
    }
  }

  return new SuggestionsQueries().searchSuggestions({
    text: value,
    retailerIds: retailerIds,
    brandIds: brandIds,
    categoryIds: categoryIds,
    searchable: [
      // "shopping_centers",
      "retailers",
      "synonyms",
      "categories",
      "brands",
    ],
  });
};

const Searchbar = () => {
  const t = usePQTranslations();
  const timeout = useRef(null);
  const inputRef = useRef(null);
  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { ccontext } = useSelector((state) => state);

  const { rStateHeaders } = ccontext;
  useEffect(() => {
    searchSuggestions("").then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  const onChange = (item = null) => {
    if (!item) return;
    let url;
    setLoading(true);
    setSelectedOption(item);

    // if the item is the kydown event
    if (!item.name) {
      // if the input typed by user is equal to the first option in the list
      //  open the option url
      if (
        options[0]?.sectionSlug &&
        options[0]?.slug &&
        options[0]?.slug === inputRef?.current?.value?.toLowerCase()
      ) {
        item = options[0];
        if (EntityTypeConstants.RETAILER == options[0].type) {
          url = RetailerUtility.getUrlNational(
            options[0].sectionSlug,
            options[0].slug,
          );
        } else {
          url = OfferUtils.getUrl(options[0].slug);
        }
        // else if the input typed by user is NOT equal to the first option in the list
        //  search the text typed
      } else if (inputRef.current.value) {
        url = SearchUtils.getUrl(inputRef.current.value);
      }
      // else if the item is an option selected from the list
    } else {
      console.log("@@@ @@@ @@@ else item.name", item.name);
      if (EntityTypeConstants.RETAILER == item.type) {
        //section/retailer
        url = RetailerUtility.getUrlNational(item.sectionSlug, item.slug);
      } else if (EntityTypeConstants.PRODUCT == item.type) {
        url = ProductUtils.getUrl(item.categorySlug, item.slug);
      } else if (
        EntityTypeConstants.OFFER == item.type &&
        item.itemName &&
        item.itemName != ""
      ) {
        url = SearchUtils.getUrlWithModel(item.itemName);
      } else if (EntityTypeConstants.SHOPPING_CENTER == item.type) {
        // url = ShoppingCenterUtils.getUrl(
        //   item.citySlug,
        //   item.sectionSlug,
        //   item.slug,
        // );
      } else {
        //include [CATEGORY, BRAND]
        url = OfferUtils.getUrl(item.slug);
      }
    }
    if (url) window.location.href = url;
  };

  const searchForItem = useCallback(
    (evt, newValue) => {
      setLoading(true);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        searchSuggestions(newValue)
          .then((newOptions) => {
            setLoading(false);
            setOptions(newOptions);
          })
          .catch((error) => PqConsole.log(error));
      }, 350);
    },
    [timeout.current],
  );

  const renderAutocompleteItem = useCallback((props, option) => {
    const key = `searchbarItem${option.name}`;
    const imgModelProps = {
      img: option.icon,
      format: ["it"].includes(pqConfs.country) ? "thumb" : "original",
    };

    return (
      <li {...props} key={key} onClick={() => onChange(option)}>
        <AutocompleteItem
          value={option.name}
          green={true}
          image={imgModelProps}
          imageClasses={[styles.searchbarImg]}
        />
      </li>
    );
  });

  let searchIcon = loading ? (
    <div className={styles.searchbarLoader}></div>
  ) : (
    <PQIcon classesAdd={[styles.searchbarButtonIcon]} icon={"search"} />
  );

  return (
    <div className={styles.searchbar}>
      <PQAutocomplete
        openOnFocus
        ListboxProps={{ style: { maxHeight: "50vh" } }}
        freeSolo
        value={selectedOption}
        noOptionsText={""}
        componentsProps={{
          paper: {
            sx: {
              width: Navigator.isTablet(rStateHeaders) ? 224 : "auto",
            },
          },
        }}
        onFocus={(event) => {
          searchForItem(event, event.target.value || "");
        }}
        onInputChange={(event, newInputValue) => {
          searchForItem(event, newInputValue);
        }}
        onChange={(event, value, reason) => {
          if (reason === "selectOption") {
            onChange(value);
          } else {
            onChange(event);
          }
        }}
        className={styles.searchbarInput}
        size="small"
        id="searchBar"
        getOptionLabel={(option) => option?.name || ""}
        renderOption={renderAutocompleteItem}
        filterOptions={(x) => x}
        options={options}
        renderInput={(params) => (
          <PQTextFieldLabelHidden
            inputRef={inputRef}
            label={t("research.what")}
            {...params}
          />
        )}
      />
      <PQButtonAutocomplete
        color="secondary"
        variant="contained"
        disabled={loading}
        onClick={() => onChange()}
        aria-label={t("research.what")}
      >
        {searchIcon}
      </PQButtonAutocomplete>
    </div>
  );
};

export const focusOnSearch = () => {
  return () => {
    document.querySelector("#searchBar").focus();
  };
};

export default Searchbar;
