import FlyerImpressionsTrack from "./FlyerImpressionsTrack";
import FlyerGibsTrack from "./FlyerGibsTrack";
import FacebookPixel, { FaceBookPixelEvent } from "./FacebookPixel";
import PqTrack from "./PqTrack";
import SFTrack from "./SFTrack";
import GTag from "./GTag";
import PqConsole from "@/commons/utilities/PqConsole";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
const { pqConfs } = useSelectorPQGlobalThis();

class Tracking {
  static exec(listPromise = []) {
    return new Promise(async (resolve, reject) => {
      try {
        const values = await Promise.all(listPromise);
        resolve(values);
      } catch (error) {
        PqConsole.error("Tacking exec", error);
        reject(error);
      }
    });
  }

  static trackPartnerLink(params, enabled = {}) {
    const { rStateIsBot } = params;
    enabled = { ...Tracking.enabledDefaultAll, ...enabled };

    if (enabled.GA) {
      const label = `${params.entityId}|${params.destination.replace(
        /https:\/\/|http:\/\//gi,
        "",
      )}`;
      GTag.sendEvent(
        GTag.CATEGORY.OFFERS,
        GTag.ACTION.OPEN_PARTNER_LINK,
        label,
      );
    }

    if (enabled.FB) {
      new FacebookPixel(rStateIsBot).trackCustom(
        FaceBookPixelEvent.EXTERNAL_LINK_CLICKED,
        {
          flyer_page: params.pageNumber,
          retailer_slug: params.retailerSlug,
          retailer_category: params.categorySlug,
        },
      );
    }

    const listPromise = [];
    if (enabled.PQ) listPromise.push(PqTrack.trackPartnerLink(params));
    if (enabled.SF) listPromise.push(FlyerGibsTrack.trackPartnerLink(params));
    return Tracking.exec(listPromise);
  }

  static leafletScrollingMode(params) {
    const { userLocation } = params;
    const labelSlugCity =
      userLocation.slug && !userLocation.default ? `|${userLocation.slug}` : "";
    const label = `${params.retailerSlug}${labelSlugCity}|${params.leafletId}|${params.switchTo}`;
    GTag.sendEvent(GTag.CATEGORY.LEAFLET, GTag.ACTION.CHANGE_LAYOUT, label);

    const listPromise = [PqTrack.leafletScrollingMode(params)];
    return Tracking.exec(listPromise);
  }

  static trackLeafletRead(params, enabled = {}) {
    const { rStateIsBot } = params;
    enabled = { ...Tracking.enabledDefaultAll, ...enabled };

    if (enabled.FB) {
      new FacebookPixel(rStateIsBot).trackLeafletRead(params);
    }

    if (enabled.GA) {
      const label = `${params.leafletShopFullyId}|true`;
      GTag.sendEvent(GTag.CATEGORY.LEAFLET, GTag.ACTION.CONVERSION, label);
    }

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackLeafletRead(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackLeafletRead(params));
    return Tracking.exec(listPromise);
  }

  static trackLeafletPageRead(params, enabled = {}) {
    const { rStateIsBot } = params;
    enabled = { ...Tracking.enabledDefaultAll, ...enabled };

    if (enabled.GA) {
      let label = pqConfs.gAnalytics.trackingPage.label;
      if (pqConfs.custom?.gAnalytics?.label) {
        label = pqConfs.custom.gAnalytics.label;
      }
      const path = `${params.retailerSlug}/${params.leafletSlug}?p=${params.pageNumber}`;
      const href = `${document.location.origin}/${path}`;
      enabled.GA && GTag.sendPageView(path, href, {}, label);
    }

    if (enabled.FB) {
      new FacebookPixel(rStateIsBot).trackCustom(
        FaceBookPixelEvent.FLYER_PAGE_BROWSE,
        {
          flyer_id: params.leafletId,
          flyer_page: params.pageNumber,
          retailer_slug: params.retailerSlug,
          retailer_category: params.categorySlug,
        },
      );
    }

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackLeafletPageRead(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackLeafletPageRead(params));
    return Tracking.exec(listPromise);
  }

  static trackLeafletLastPageRead(params, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultAll, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackLeafletLastPageRead(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackLeafletLastPageRead(params));
    return Tracking.exec(listPromise);
  }

  static trackLeafletReadingTime(params) {
    const listPromise = [PqTrack.trackLeafletReadingTime(params)];
    return Tracking.exec(listPromise);
  }

  static trackAdvPageImpression(params) {
    const { userLocation } = params;
    const labelZipCode =
      userLocation.zipCode && !userLocation.default
        ? `|${userLocation.zipCode}`
        : "";
    const label = `${params.id}${labelZipCode}|${params.retailerSlug}|${params.leafletId}`;
    GTag.sendEvent(GTag.CATEGORY.LEAFLET, GTag.ACTION.ADV_PAGE, label);

    const listPromise = [PqTrack.trackAdvPageImpression(params)];
    return Tracking.exec(listPromise);
  }

  static trackAdvPageClick(params) {
    const { userLocation } = params;
    const labelZipCode =
      userLocation.zipCode && !userLocation.default
        ? `|${userLocation.zipCode}`
        : "";
    const label = `${params.id}${labelZipCode}|${params.retailerSlug}|${params.leafletId}`;
    GTag.sendEvent(GTag.CATEGORY.LEAFLET, GTag.ACTION.ADV_PAGE_CLICK, label);

    const listPromise = [PqTrack.trackAdvPageClick(params)];
    return Tracking.exec(listPromise);
  }

  static trackV2(params) {
    const { rStateLocationHref, rStateIsBot } = params;
    new FacebookPixel(rStateIsBot).trackCustom(FaceBookPixelEvent.API_V2, {
      ...params,
      href: rStateLocationHref.href,
    });
  }

  //--- FLYER GIBS ---
  static trackTapMemo(params = {}, enabled = {}) {
    enabled = { ...{ PQ: false, SF: false, FB: false, GA: true }, ...enabled };

    if (enabled.GA) GTag.trackTapMemo(params);

    const listPromise = [];
    if (enabled.SF) listPromise.push(FlyerGibsTrack.trackTapMemo(params));
    //if (enabled.PQ) listPromise.push(FlyerGibsTrack.trackTapMemo(params));
    return Tracking.exec(listPromise);
  }

  static trackTapMoreInfo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(FlyerGibsTrack.trackTapMoreInfo(params));
    //if (enabled.PQ) listPromise.push(FlyerGibsTrack.trackTapMemo(params));
    return Tracking.exec(listPromise);
  }

  static trackImpressionProductDetails(params = {}, enabled = {}) {
    enabled = { ...{ PQ: false, SF: false, FB: false, GA: true }, ...enabled };

    if (enabled.GA) GTag.trackImpressionProductDetails(params);

    const listPromise = [];
    if (enabled.SF)
      listPromise.push(FlyerGibsTrack.trackImpressionProductDetails(params));
    //if (enabled.PQ) listPromise.push(FlyerGibsTrack.trackImpressionProductDetails(params));
    return Tracking.exec(listPromise);
  }

  static trackPlayVideo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(FlyerGibsTrack.trackPlayVideo(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackPlayVideo(params));
    return Tracking.exec(listPromise);
  }

  static trackViewedVideo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(FlyerGibsTrack.trackViewedVideo(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackViewedVideo(params));
    return Tracking.exec(listPromise);
  }

  //--- S2S ----
  static trackS2SOfferOpen(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SOfferOpen(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SOfferOpen(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SFlyerOpen(params = {}, enabled = {}) {
    const { rStateIsBot } = params;
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    if (enabled.FB) {
      new FacebookPixel(rStateIsBot).trackS2SFlyerOpen(params);
    }

    if (enabled.GA) {
      const label = `${params.leafletSFId}|true`;
      GTag.sendEvent(GTag.CATEGORY.LEAFLET, GTag.ACTION.CONVERSION, label);
    }

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SFlyerOpen(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SFlyerOpen(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SViewProductCarousel(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF)
      listPromise.push(SFTrack.trackS2SViewProductCarousel(params));
    if (enabled.PQ)
      listPromise.push(PqTrack.trackS2SViewProductCarousel(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SViewLastProduct(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SViewLastProduct(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SViewLastProduct(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SShowDescription(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SShowDescription(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SShowDescription(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SCallPhone(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SCallPhone(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SCallPhone(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SProductInfo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SProductInfo(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SProductInfo(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SOpenAllStores(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SOpenAllStores(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SOpenAllStores(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SNavigateTo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SNavigateTo(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SNavigateTo(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SGoTo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SGoTo(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SGoTo(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SEnd(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SEnd(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SEnd(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SSwipe(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SSwipe(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SSwipe(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SOfferView(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SOfferView(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SOfferView(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SReminder(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefault_PQ_SF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SReminder(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SReminder(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SOpenRetailerPage(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SOpenRetailerPage(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SOpenRetailerPage(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SCloseDetail(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SCloseDetail(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SCloseDetail(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SOpenDetail(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SOpenDetail(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SOpenDetail(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SFilterSelected(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SFilterSelected(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SFilterSelected(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SHeroSection(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SHeroSection(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SHeroSection(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SViewAllSimilarProduct(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF)
      listPromise.push(SFTrack.trackS2SViewAllSimilarProduct(params));
    if (enabled.PQ)
      listPromise.push(PqTrack.trackS2SViewAllSimilarProduct(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SViewProductSimilarProduct(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF)
      listPromise.push(SFTrack.trackS2SViewProductSimilarProduct(params));
    if (enabled.PQ)
      listPromise.push(PqTrack.trackS2SViewProductSimilarProduct(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SStartVideo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SStartVideo(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SStartVideo(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SViewedVideo(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SViewedVideo(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SViewedVideo(params));
    return Tracking.exec(listPromise);
  }

  static trackS2SToggleView(params = {}, enabled = {}) {
    enabled = { ...Tracking.enabledDefaultOnlySF, ...enabled };

    const listPromise = [];
    if (enabled.SF) listPromise.push(SFTrack.trackS2SToggleView(params));
    if (enabled.PQ) listPromise.push(PqTrack.trackS2SToggleView(params));
    return Tracking.exec(listPromise);
  }

  static trackFlayersImpression(
    batchLimitItemBatch = [],
    userLocation,
    rStateHeaders,
    rStateDeviceUser,
    rStateIsBot,
  ) {
    const listPromise = [
      FlyerImpressionsTrack.trackFlayersImpression(
        batchLimitItemBatch,
        userLocation,
        rStateHeaders,
        rStateDeviceUser,
        rStateIsBot,
      ),
    ];
    return Tracking.exec(listPromise);
  }
}

Tracking.enabledDefaultAll = { PQ: true, SF: true, FB: true, GA: true };
Tracking.enabledDefaultOnlySF = { PQ: false, SF: true, FB: false, GA: false };
Tracking.enabledDefault_PQ_SF = { PQ: true, SF: true, FB: false, GA: false };

Tracking.SOURCE = {
  PARTNER_LINK: {
    FROM_LEAFLET: "from_leaflet",
    FROM_OFFER_LIST: "from_offer_list",
    FROM_POPUP: "from_popup",
  },
};

export default Tracking;
