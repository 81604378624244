import Carousel from "../carousel/Carousel";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import React from "react";
import { focusOnSearch } from "../searchbar/Searchbar";
import styles from "./PromoQuiCarousel.module.scss";
import usePQTranslations from "@/commons/hooks/usePQTranslations";

/* Questo componente sarà quello generico per poter gestire tutti i caroselli del sito */

export interface IAheadItem {
  key: string;
  url: string;
  icon: string;
  name: string;
  color: string;
}

interface Props {
  data: any;
  seeMoreURL?: string;
  seeMoreLabel?: string;
  moreUrl?: boolean;
  slidesToScroll?: number;
  containerClass?: string;
  renderItem(item: any, index?: number, lazyLoad?: boolean): void;
  aheadItems?: IAheadItem[];
  renderAheadItems?(aheadItem: IAheadItem, index?: number): void;
  lazyLoad?: boolean;
}

const PromoQuiCarousel: React.FC<Props> = ({
  data,
  seeMoreURL,
  seeMoreLabel,
  moreUrl,
  renderItem,
  slidesToScroll = 4,
  containerClass = null,
  aheadItems = [],
  renderAheadItems = null,
  lazyLoad = true,
}: Props) => {
  const t = usePQTranslations();

  return (
    <div className={styles.offersCarousel}>
      <Carousel slidesToScroll={slidesToScroll} containerClass={containerClass}>
        {aheadItems.map((aheadItem, index) =>
          renderAheadItems(aheadItem, index),
        )}
        {data.map((item, index: number) => renderItem(item, index, lazyLoad))}
        {moreUrl && seeMoreLabel && (
          <div
            onClick={focusOnSearch()}
            className={styles.offersCarouselSeeMore}
          >
            <div className={styles.offersCarouselSeeMoreText}>
              {seeMoreLabel}
            </div>
            <PQIcon icon={"chevron-right"} />
          </div>
        )}
      </Carousel>
    </div>
  );
};
export default PromoQuiCarousel;
