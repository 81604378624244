import Queries from "./Queries";

class SettingsQueries extends Queries {
  getResult(result) {
    if (result.data && result.data.Settings) {
      const settings = {};
      result.data.Settings.forEach((set) => {
        const { name, value } = set;
        if (
          [
            SettingsQueries.REQUEST.PQ_STAGING[0],
            SettingsQueries.REQUEST.PQ_PRODUCTION[0],
            SettingsQueries.REQUEST.PQ_FLYERS_GIBS_DEV[0],
            SettingsQueries.REQUEST.PQ_FLYERS_GIBS_PROD[0],
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_DEV[0],
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_PROD[0],
          ].indexOf(name) >= 0
        ) {
          settings.url = value;
        } else if (
          [
            SettingsQueries.REQUEST.PQ_STAGING[1],
            SettingsQueries.REQUEST.PQ_PRODUCTION[1],
            SettingsQueries.REQUEST.PQ_FLYERS_GIBS_DEV[1],
            SettingsQueries.REQUEST.PQ_FLYERS_GIBS_PROD[1],
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_DEV[1],
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_PROD[1],
          ].indexOf(name) >= 0
        ) {
          settings.token = value;
        } else if (
          [
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_DEV[2],
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_PROD[2],
          ].indexOf(name) >= 0
        ) {
          settings.minEventDuration = value;
        } else if (
          [
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_DEV[3],
            SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_PROD[3],
          ].indexOf(name) >= 0
        ) {
          settings.itemVisiblePercentThreshold = value;
        }
      });

      return settings;
    }
    return null;
  }

  getByNames(params) {
    const { names } = params;

    const name = `Settings_getByNames($names:[String!])`;
    const data = `id name value`;
    const query = `Settings(names: $names)`;
    const variables = { names: names };

    return this.execQuery(name, query, data, [], variables);
  }
}

SettingsQueries.REQUEST = {
  URL: "shopfully_tracking_node",
  TOKEN: "shopfully_tracking_token",
  PQ_STAGING: [
    "shopfully_tracking_node_pq.staging",
    "shopfully_tracking_token_pq.staging",
  ],
  PQ_PRODUCTION: ["shopfully_tracking_node_pq", "shopfully_tracking_token_pq"],

  PQ_FLYERS_GIBS_PROD: [
    "shopfully_pq_prod_flyers_gibs",
    "shopfully_pq_token_prod_flyers_gibs",
  ],
  PQ_FLYERS_GIBS_DEV: [
    "shopfully_pq_dev_flyers_gibs",
    "shopfully_pq_token_dev_flyers_gibs",
  ],

  PQ_FLYERS_IMPRESSIONS_PROD: [
    "shopfully_pq_prod_impressions",
    "shopfully_pq_token_prod_impressions",
    "shopfully_pq_prod_impressions_minEventDuration",
    "shopfully_pq_prod_impressions_itemVisiblePercentThreshold",
  ],
  PQ_FLYERS_IMPRESSIONS_DEV: [
    "shopfully_pq_dev_impressions",
    "shopfully_pq_token_dev_impressions",
    "shopfully_pq_dev_impressions_minEventDuration",
    "shopfully_pq_dev_impressions_itemVisiblePercentThreshold",
  ],
};

export default SettingsQueries;
