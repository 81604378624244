import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import style from "./PQImg.module.scss";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PQImgConstants from "@/commons/ui/constants/PQImgConstants";
import PqConsole from "@/commons/utilities/PqConsole";
import { ImgModel } from "types/imgModel";

interface Props {
  imgStatic?: string | string[];
  imgModel?: ImgModel;
  altText?: string;
  styleImg?: any;
  classNameImage?: [];
  classNameContent?: any;
  onClickImage?(): void;
  lazyLoad?: boolean;
  ariaLabel?: string;
  blurPlaceholder?: string;
  pWidth?: string;
  pHeight?: string;
}

const PQImg = ({
  imgStatic,
  imgModel,
  altText = "",
  ariaLabel,
  styleImg,
  classNameImage,
  classNameContent = [],
  onClickImage,
  lazyLoad = true,
  blurPlaceholder,
  pWidth,
  pHeight,
}: Props) => {
  const { ccontext }: any = useSelector((state) => state);
  const { rStateAcceptWebP, rStateIsChromeLighthouse, rStateIsBot } = ccontext;

  const { pqImages, pqConfs } = useSelectorPQGlobalThis();
  lazyLoad = lazyLoad && !pqConfs.settings.disableImagesLazyLoad;

  // disable lazyload if it's a bot but not a lighouse
  lazyLoad = lazyLoad && !(rStateIsBot && !rStateIsChromeLighthouse);

  const imgRef = useRef(null);
  const [loading, setLoading] = useState(
    lazyLoad ? PQImgConstants.state.LOADING : PQImgConstants.state.LOAD_OK,
  );
  const [dimension, setDimension] = useState(null);

  const isLoading = useMemo(
    () => loading == PQImgConstants.state.LOADING,
    [loading],
  );
  const isLoadOk = useMemo(
    () => loading == PQImgConstants.state.LOAD_OK,
    [loading],
  );
  const isLoadFail = useMemo(
    () => loading == PQImgConstants.state.LOAD_FAIL,
    [loading],
  );

  // --- didMount ---
  useEffect(() => {
    const width = pWidth || imgRef?.current?.width || 0;
    const height = pHeight || imgRef?.current?.height || 0;
    if (!isLoadOk || width <= 0 || height <= 0) return;
    setDimension({ width, height });
  }, [isLoadOk, lazyLoad, imgRef, pWidth, pHeight]);

  useEffect(() => {
    const width = pWidth || imgRef?.current?.width || 0;
    const height = pHeight || imgRef?.current?.height || 0;

    if (!isLoading || width <= 0 || height <= 0 || !blurPlaceholder) return;
    setDimension({ width, height });
  }, [isLoading, lazyLoad, imgRef, blurPlaceholder, pWidth, pHeight]);

  // --- lazy loading ---
  useEffect(() => {
    if (!lazyLoad || !imgRef?.current) return;

    const options = {
      root: document.querySelector("body"),
      rootMargin: "0px",
      threshold: 0.05,
    };

    const observer = new IntersectionObserver(
      handlerImageOnIntersection,
      options,
    );
    observer.observe(imgRef.current);
  }, []);

  const handlerImageOnIntersection = (entries, observer) => {
    if (!isLoadOk && entries[0].isIntersecting) {
      loadImage()
        .then((img) => {
          setLoading(PQImgConstants.state.LOAD_OK);
        })
        .catch((error) => {
          PqConsole.error("handlerImageOnIntersection", error);
          setLoading(PQImgConstants.state.LOAD_FAIL);
        });
    }
  };

  const loadImage = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const img = await loadImageAsync(getUrlImage);
        resolve(img);
      } catch (error) {
        reject(false);
      }
    });
  };

  const loadImageAsync = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = (response) => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = url;
    });
  };

  const renderPicture = () => {
    let classListContent: any = [
      style.pqImg,
      ...[isArray(classNameContent) ? classNameContent : [classNameContent]],
    ];
    let classListImg: any = [
      style.pqImgPicture,
      ...[isArray(classNameImage) ? classNameImage : [classNameImage]],
    ];
    if ((isLoading || isLoadFail) && !blurPlaceholder)
      classListImg.push(style.pqImgPictureLoader);
    classListContent = classListContent.join(" ");
    classListImg = classListImg.join(" ");

    const placeHolder = blurPlaceholder ? blurPlaceholder : pqImages?.loader;
    const width = dimension?.width;
    const height = dimension?.height;

    return (
      <div className={classListContent}>
        <img
          ref={imgRef}
          src={true ? getUrlImage : placeHolder}
          alt={altText}
          aria-label={ariaLabel}
          title={altText}
          style={styleImg}
          onClick={onClickImage}
          className={classListImg}
          width={width}
          height={height}
        />
      </div>
    );
  };

  const getUrlImage = useMemo(() => {
    let url;
    if (imgStatic) {
      if (isArray(imgStatic)) {
        url = (imgStatic as string[]).join(", ");
      } else {
        url = imgStatic;
      }
    } else {
      let { img, webp, supportedFormat = [], format = "", baseUrl } = imgModel;
      const existsWebP = webp && includes(supportedFormat, format);
      if (existsWebP && rStateAcceptWebP) {
        url = webp.getFormat(format);
      } else {
        url = img?.getFormat(format);
      }
      url = `${baseUrl || pqConfs?.dataAsset?.url}/${url}`;
    }
    return url;
  }, [imgStatic, imgModel, rStateAcceptWebP, pqConfs?.dataAsset?.url]);

  //--- RENDER ---
  return renderPicture();
};

// PQImg.defaultProps = {
// 	imgStatic: null,
// 	imgModel: { img: null, webp: null, supportedFormat: [], format: '' },
// 	altText: '',
// 	styleImg: {},
// 	classNameImage: [],
// 	classNameContent: [],
// 	onClickImage: () => {},
// 	lazyLoad: true,
// 	ariaLabel: null,
// 	blurPlaceholder: null,
// };

export default PQImg;
