import { v5 as uuidv5 } from "uuid";
import DeviceIdUtility from "./DeviceIdUtility";

class DCIDUtility {
  static get(rStateDeviceUser) {
    const device = DeviceIdUtility.get(rStateDeviceUser);
    const nameSpace = "9f282611-e0fd-5650-8953-89c8e342da0b";
    return uuidv5(device.id.toString(), nameSpace);
  }

  static getRandom() {
    const nameSpace = "9f282611-e0fd-5650-8953-89c8e342da0b";
    const key = (
      parseInt(Math.random() * (1000 - 1) + 1) + new Date().getTime()
    ).toString();
    return uuidv5(key, nameSpace);
  }
}

export default DCIDUtility;
