import SettingsQueries from "@/commons/dataset/queries/SettingsQueries";
import merge from "lodash-es/merge";
import DCIDUtility from "@/commons/utilities/DCIDUtility";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PqConsole from "@/commons/utilities/PqConsole";
import Navigator from "@/commons/utilities/Navigator";
import TrackingUtility from "@/commons/utilities/TrackingUtility";
import BrowserUtility from "@/commons/utilities/BrowserUtility";
import CreateTrackingMetaUtility from "@/commons/utilities/CreateTrackingMetaUtility";

const { pqConfs } = useSelectorPQGlobalThis();

class FlyerGibsTrack {
  static settingsData = null;

  static printEvent(trackObj, name, url) {
    if (pqConfs.env == "staging")
      PqConsole.log(`FLYER GIBS: ${name}`, { url, trackObj });
  }

  static getTrackingUrl() {
    return new Promise(async (resolve, reject) => {
      if (!FlyerGibsTrack.settingsData) {
        let namesSetting =
          pqConfs.env == "staging"
            ? SettingsQueries.REQUEST.PQ_FLYERS_GIBS_DEV
            : SettingsQueries.REQUEST.PQ_FLYERS_GIBS_PROD;

        try {
          const settingsQueries = new SettingsQueries();
          const result = await settingsQueries.getByNames({
            names: namesSetting,
          });
          if (result) {
            FlyerGibsTrack.settingsData = result;
            resolve(result);
          } else {
            reject(
              new Error(
                "FlyerGibsTrack::getTrackingParams: impossibile caricare settings url e token",
              ),
            );
          }
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(FlyerGibsTrack.settingsData);
      }
    });
  }

  static requestHeaderParams(rStateHeaders, rStateDeviceUser) {
    const headerParams = {
      h: {
        dmh: screen.height.toString(),
        dmw: screen.width.toString(),
        dmd: window.devicePixelRatio.toString(),
        bv: pqConfs.appVersion,
        uid: DCIDUtility.get(rStateDeviceUser),
        pn: `${pqConfs.country}.pq`,
        ct: pqConfs.country.toUpperCase(), //PTI-204
        tp: !Navigator.isWebView()
          ? TrackingUtility.CONSTANTS.TYPE.WEB
          : TrackingUtility.CONSTANTS.TYPE.MOBILE,
        sn: BrowserUtility.getOS(),
        brn: BrowserUtility.getName(),
        brv: BrowserUtility.getVersion(),
        pid: "_pq_",
        dv: null,
        is: null,
        mn: null,
        cuid: null,
        aid: null,
        av: null,
        sv: null,
        cr: null,
      },
      v: "1.0",
    };
    return headerParams;
  }

  static requestCommonParams(userLocation) {
    const commonParams = {
      at: {
        ...TrackingUtility.getLocationParams(userLocation), //lat, lng, lm, la
      },
      ts: new Date().toISOString(),
      ei: DCIDUtility.getRandom(),
    };

    return commonParams;
  }

  static sendTrack(
    trackObj,
    name,
    rStateHeaders,
    rStateDeviceUser,
    rStateIsBot,
  ) {
    return new Promise(async (resolve, reject) => {
      if (
        TrackingUtility.skipTrack(FlyerGibsTrack.VALID_COUNTRIES, rStateIsBot)
      ) {
        resolve();
      } else {
        try {
          const settingsData = await FlyerGibsTrack.getTrackingUrl();
          if (settingsData) {
            const trackObjHeader = FlyerGibsTrack.requestHeaderParams(
              rStateHeaders,
              rStateDeviceUser,
            );
            const request = { ...trackObjHeader, ...trackObj };
            const options = {
              method: "post",
              keepalive: true, //garntisce che l'evento termini prima della chiusura
              body: JSON.stringify(request),
            };
            const { ts, et, st } = CreateTrackingMetaUtility(
              settingsData.token,
            );
            const url = `${settingsData.url}?ts=${ts}&et=${et}&st=${st}`;
            const result = await fetch(url, options);
            if (result) {
              resolve(result);
              FlyerGibsTrack.printEvent(request, name, settingsData.url);
            } else {
              PqConsole.error(`FlyerGibs [${name}] not work`);
              reject(error);
            }
          } else {
            PqConsole.error(`FlyerGibs [${name}] not work`);
          }
        } catch (error) {
          PqConsole.error(`FlyerGibs [${name}] not work`, error);
          reject(error);
        }
      }
    });
  }

  static trackTapMemo(params) {
    const {
      leafletShopFullyId,
      offerShopFullyId,
      userLocation,
      rStateHeaders,
      rStateDeviceUser,
      rStateAppRender,
      rStateIsBot,
    } = params;

    const trackObjEvent = {
      at: {
        utms: TrackingUtility.CONSTANTS.UTMS.DIRECT,
        text: TrackingUtility.CONSTANTS.TEXT.MEMO,
        origin: TrackingUtility.CONSTANTS.ORIGIN.PRODUCT_LIST,
        utmm: TrackingUtility.getUTMMFromPage(rStateAppRender),
        fgid: `${offerShopFullyId}`,
        fid: `${leafletShopFullyId}`,
      },
      et: TrackingUtility.CONSTANTS.ET.FLYER_GIB_BUTTON,
    };

    const trackObjComplete = {
      e: [
        merge(FlyerGibsTrack.requestCommonParams(userLocation), trackObjEvent),
      ],
    };

    return FlyerGibsTrack.sendTrack(
      trackObjComplete,
      "trackTapMemo",
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackTapMoreInfo(params) {
    const {
      leafletShopFullyId,
      leafletId,
      offerShopFullyId,
      offerId,
      text,
      url,
      userLocation,
      rStateHeaders,
      rStateDeviceUser,
      rStateAppRender,
      rStateIsBot,
    } = params;
    const trackObjEvent = {
      at: {
        utmm: TrackingUtility.getUTMMFromPage(rStateAppRender),
        utms: TrackingUtility.CONSTANTS.UTMS.DIRECT,
        origin: TrackingUtility.CONSTANTS.ORIGIN.PRODUCT_LIST,
        _fgid: `${offerId}`,
        fgid: `${offerShopFullyId}`,
        _fid: leafletId,
        fid: `${leafletShopFullyId}`,
        text: text,
        url: url,
      },
      et: TrackingUtility.CONSTANTS.ET.FLYER_GIB_BUTTON,
    };
    const trackObjComplete = {
      e: [
        merge(FlyerGibsTrack.requestCommonParams(userLocation), trackObjEvent),
      ],
    };

    return FlyerGibsTrack.sendTrack(
      trackObjComplete,
      "trackTapMemo",
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackImpressionProductDetails(params) {
    const {
      leafletShopFullyId,
      offerShopFullyId,
      userLocation,
      rStateHeaders,
      rStateDeviceUser,
      rStateAppRender,
      rStateIsBot,
    } = params;

    const trackObjEvent = {
      at: {
        utms: TrackingUtility.CONSTANTS.UTMS.DIRECT,
        origin: TrackingUtility.CONSTANTS.ORIGIN.PRODUCT_LIST,
        utmm: TrackingUtility.getUTMMFromPage(rStateAppRender),
        fggid: "-1", //PTI-204
        fgo: "0", //PTI-204
        fgid: `${offerShopFullyId}`,
        fid: `${leafletShopFullyId}`,
      },
      et: TrackingUtility.CONSTANTS.ET.FLYER_GIB,
    };

    const trackObjComplete = {
      e: [
        merge(FlyerGibsTrack.requestCommonParams(userLocation), trackObjEvent),
      ],
    };

    return FlyerGibsTrack.sendTrack(
      trackObjComplete,
      "trackImpressionProductDetails",
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackPartnerLink(params) {
    const {
      leafletShopFullyId,
      offerShopFullyId,
      destination,
      userLocation,
      rStateHeaders,
      rStateDeviceUser,
      rStateAppRender,
      rStateIsBot,
    } = params;

    const trackObjEvent = {
      at: {
        utms: TrackingUtility.CONSTANTS.UTMS.DIRECT,
        origin: TrackingUtility.CONSTANTS.ORIGIN.PRODUCT_LIST,
        utmm: TrackingUtility.getUTMMFromPage(rStateAppRender),
        fggid: "-1", //PTI-204
        fgo: "0", //PTI-204
        fgid: `${offerShopFullyId}`,
        fid: `${leafletShopFullyId}`,
        url: `${destination}`,
      },
      et: TrackingUtility.CONSTANTS.ET.FLYER_GIB,
    };

    const trackObjComplete = {
      e: [
        merge(FlyerGibsTrack.requestCommonParams(userLocation), trackObjEvent),
      ],
    };

    return FlyerGibsTrack.sendTrack(
      trackObjComplete,
      "trackPartnerLink",
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackPlayVideo(params = {}) {
    const {
      leafletId,
      leafletSFId,
      offerId,
      offerSFId,
      userLocation,
      rStateHeaders,
      rStateDeviceUser,
      rStateAppRender,
      rStateIsBot,
    } = params;

    const trackObjEvent = {
      at: {
        event_type: FlyerGibsTrack.EVENT_ACTION.PLAY_VIDEO,
        _fgid: `${leafletId}`,
        fgid: `${leafletSFId}`,
        _fid: `${offerId}`,
        fid: `${offerSFId}`,
        origin: TrackingUtility.CONSTANTS.ORIGIN.PRODUCT_LIST,
        utmm: TrackingUtility.getUTMMFromPage(rStateAppRender),
        utms: TrackingUtility.CONSTANTS.UTMS.DIRECT,
      },
    };

    const trackObjComplete = {
      e: [
        merge(FlyerGibsTrack.requestCommonParams(userLocation), trackObjEvent),
      ],
    };

    return FlyerGibsTrack.sendTrack(
      trackObjComplete,
      "trackPlayVideo",
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackViewedVideo(params = {}) {
    const {
      leafletId,
      leafletSFId,
      offerId,
      offerSFId,
      percentage,
      isSeekVideo,
      userLocation,
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    } = params;
    const trackObjEvent = {
      at: {
        event_type: FlyerGibsTrack.EVENT_ACTION.VIEWED_VIDEO,
        _fgid: `${leafletId}`,
        fgid: `${leafletSFId}`,
        _fid: `${offerId}`,
        fid: `${offerSFId}`,
        pvv: percentage,
        mode: isSeekVideo ? "ff" : "noff",
      },
    };

    const trackObjComplete = {
      e: [
        merge(FlyerGibsTrack.requestCommonParams(userLocation), trackObjEvent),
      ],
    };

    return FlyerGibsTrack.sendTrack(
      trackObjComplete,
      "trackViewedVideo",
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
  }
}
FlyerGibsTrack.EVENT_ACTION = {
  START_VIDEO: "sv",
  VIEWED_VIDEO: "vv",
  PLAY_VIDEO: "pv",
};

FlyerGibsTrack.VALID_COUNTRIES = ["it"];

export default FlyerGibsTrack;
