import EnvUtility from "./EnvUtility";
import Tracking from "@/commons/tracking/Tracking";
import LocalStorageKeys from "@/commons/keys/LocalStorageKeys";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PqConsole from "@/commons/utilities/PqConsole";

const tolleranceBatch = 3 * 60 * 1000; //3 minuti
const limitItemBatch = 100;
const intervalTrackProcess = 15 * 1000; //15 secondi

class IntersectionLeafletUtility {
  static observer;

  static rStateAppRender = null;

  static init(rStateAppRender) {
    IntersectionLeafletUtility.rStateAppRender = rStateAppRender;

    if (EnvUtility.isServerSide) return;

    const options = {
      root: document.querySelector("html"),
      rootMargin: "0px",
      threshold: 0.5,
    };
    IntersectionLeafletUtility.observer = new IntersectionObserver(
      IntersectionLeafletUtility.eventIntersection,
      options,
    );
  }

  static eventIntersection(entries, observer) {
    let lsLeaflets = IntersectionLeafletUtility.getLocalStorageLeaflet();
    entries.forEach((entry) => {
      const indexElem = IntersectionLeafletUtility.searchIndexInListByElemDom(
        entry.target,
      );
      if (indexElem >= 0) {
        const leaflet = IntersectionLeafletUtility.list[indexElem].leaflet;
        const position = IntersectionLeafletUtility.list[indexElem].position;
        if (entry.isIntersecting) {
          lsLeaflets = IntersectionLeafletUtility.onElementyEntry(
            leaflet,
            position,
            lsLeaflets,
          );
        } else {
          lsLeaflets = IntersectionLeafletUtility.onElementyExit(
            leaflet,
            lsLeaflets,
          );
        }
      }
    });
    localStorage.setItem(
      LocalStorageKeys.KEY_INTERSECTION_LEAFLET,
      JSON.stringify(lsLeaflets),
    );
  }

  static onElementyEntry(leaflet, position, lsLeaflets = []) {
    const index = lsLeaflets.findIndex(
      (l) => l.leafletId == leaflet.id && l.timeEnd == null,
    );
    if (index >= 0) {
      lsLeaflets[index].timeStart = new Date().getTime();
    } else {
      const categoryId =
        leaflet.categories && leaflet.categories.length > 0
          ? leaflet.categories[0].id
          : null;

      lsLeaflets.push({
        leafletId: leaflet.id,
        leafletShopFullyId: leaflet.shopfullyId,
        categoryId: categoryId,
        order: position,
        boost: leaflet.boost,
        retailer: leaflet.retailer.name,
        type: leaflet.type,
        title: leaflet.title,
        timeStart: new Date().getTime(),
        timeEnd: null,
        page: IntersectionLeafletUtility.rStateAppRender,
      });
    }

    return lsLeaflets;
  }

  static onElementyExit(leaflet, lsLeaflets = []) {
    const index = lsLeaflets.findIndex(
      (l) => l.leafletId == leaflet.id && l.timeEnd == null,
    );
    if (index >= 0) {
      lsLeaflets[index].timeEnd = new Date().getTime();
    }
    return lsLeaflets;
  }

  static closeLocalStorageLeaflet() {
    let lsLeaflets = IntersectionLeafletUtility.getLocalStorageLeaflet();
    lsLeaflets = lsLeaflets.map((leaflet) => {
      const now = new Date().getTime();
      if (
        leaflet.timeEnd === null &&
        now - leaflet.timeStart < tolleranceBatch
      ) {
        return { ...leaflet, timeEnd: new Date().getTime() };
      } else {
        return leaflet;
      }
    });
    localStorage.setItem(
      LocalStorageKeys.KEY_INTERSECTION_LEAFLET,
      JSON.stringify(lsLeaflets),
    );
    return lsLeaflets;
  }

  static cleanLocalStorageLeaflet() {
    let lsLeaflets = IntersectionLeafletUtility.getLocalStorageLeaflet();
    lsLeaflets = lsLeaflets.filter((leaflet) => {
      const now = new Date().getTime();
      const excludeGTEMinuts =
        leaflet.timeEnd == null && now - leaflet.timeStart > tolleranceBatch;
      const excludeLTEMinuts =
        leaflet.timeEnd !== null && leaflet.timeEnd - leaflet.timeStart < 500;
      return !(excludeGTEMinuts || excludeLTEMinuts);
    });
    localStorage.setItem(
      LocalStorageKeys.KEY_INTERSECTION_LEAFLET,
      JSON.stringify(lsLeaflets),
    );

    return lsLeaflets;
  }

  static getLocalStorageLeaflet() {
    let lsLeaflets = [];
    try {
      lsLeaflets =
        JSON.parse(
          localStorage.getItem(LocalStorageKeys.KEY_INTERSECTION_LEAFLET),
        ) || [];
    } catch (error) {
      lsLeaflets = [];
    }
    return lsLeaflets;
  }

  static addElement(leaflet, domElem, position, rStateAppRender) {
    const idxElem =
      IntersectionLeafletUtility.searchIndexInListByElemDom(domElem);
    if (idxElem < 0) {
      IntersectionLeafletUtility.list.push({ leaflet, domElem, position });
      if (!IntersectionLeafletUtility.observer) {
        IntersectionLeafletUtility.init(rStateAppRender);
      }
      IntersectionLeafletUtility.observer.observe(domElem);
    }
  }

  static setProcessBGTrackEvent(
    userLocation,
    rStateHeaders,
    rStateDeviceUser,
    rStateIsBot,
  ) {
    IntersectionLeafletUtility.processBGTrackEvent(
      userLocation,
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
    setInterval(() => {
      IntersectionLeafletUtility.processBGTrackEvent(
        userLocation,
        rStateHeaders,
        rStateDeviceUser,
        rStateIsBot,
      );
    }, intervalTrackProcess);
  }

  static processBGTrackEvent(
    userLocation,
    rStateHeaders,
    rStateDeviceUser,
    rStateIsBot,
  ) {
    IntersectionLeafletUtility.cleanLocalStorageLeaflet();
    const lsLeaflets = IntersectionLeafletUtility.getLocalStorageLeaflet();
    const leafletsForBatchTrack = lsLeaflets.filter(
      (leaflet) => leaflet.timeStart && leaflet.timeEnd,
    );
    while (leafletsForBatchTrack && leafletsForBatchTrack.length > 0) {
      let batchLeaflets = leafletsForBatchTrack.splice(0, limitItemBatch);
      Tracking.trackFlayersImpression(
        batchLeaflets,
        userLocation,
        rStateHeaders,
        rStateDeviceUser,
        rStateIsBot,
      )
        .then((result) => {
          let lsLeaflets = IntersectionLeafletUtility.getLocalStorageLeaflet();
          lsLeaflets = lsLeaflets.filter(
            (x) =>
              !batchLeaflets.some(
                (y) => JSON.stringify(x) == JSON.stringify(y),
              ),
          );
          localStorage.setItem(
            LocalStorageKeys.KEY_INTERSECTION_LEAFLET,
            JSON.stringify(lsLeaflets),
          );
        })
        .catch((error) => {
          PqConsole.error("ERRORE: batch impression cover ", error);
          //PqConsole.error(JSON.stringify(batchLeaflets));
        });
    }
  }

  static searchIndexInListByElemDom(elemDom) {
    return IntersectionLeafletUtility.list.findIndex(
      (elemList) => elemList.domElem === elemDom,
    );
  }
}

IntersectionLeafletUtility.list = [];

export default IntersectionLeafletUtility;
