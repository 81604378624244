import React, { ReactElement, useCallback, useEffect, useState } from "react";

import CountryKeys from "@/commons/keys/CountryKeys";
import Head from "next/head";
import { ccontextSelector } from "@/commons/redux/selectors/ccontext";
import style from "./PQHead.module.scss";
import { useSelector } from "react-redux";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import OneTrustUtils from "@/commons/tracking/OneTrustUtils";

const enableClarity = false;

interface Props {
  title: string;
  description: string;
  children?: React.ReactChildren | ReactElement<any, any>;
  environment: any;
}

const PQHead = ({ children, title, description, environment }: Props) => {
  const { rStateLocationHref, rStateDeviceUser, rStateIsBot } =
    useSelector(ccontextSelector);
  const { pqImages, pqConfs } = useSelectorPQGlobalThis();
  const [loadOneTrust, setLoadOneTrust] = useState(false);

  useEffect(() => {
    setLoadOneTrust(true);
    console.log("@@@#-- OneTrust Load");
  }, []);

  const renderOneTrust = useCallback(() => {
    if (rStateIsBot) return;

    const optanonWrapper = `
      function OptanonWrapper() {
          
        OneTrust.OnConsentChanged(function(e) {
          console.log("@@@### OnConsentChanged e.detail", e.detail);

          const purposes = e.detail.reduce((obj, item) => {
            const key = item;
            const value = true;
            return {
              ...obj,
              [key]: value
            }
          },{})
          OneTrustUtils.setPreferencesUser({purposes});
        });
      }
    `;

    return (
      <>
        <script
          async={pqConfs.onetrust.script.async}
          src={pqConfs.onetrust.script.src}
          type={pqConfs.onetrust.script.type}
          data-domain-script={pqConfs.onetrust.script.dataDomainScript}
        ></script>
        <script type="text/javascript">{optanonWrapper}</script>
      </>
    );
  }, [
    pqConfs.onetrust.script.async,
    pqConfs.onetrust.script.dataDomainScript,
    pqConfs.onetrust.script.src,
    pqConfs.onetrust.script.type,
    rStateIsBot,
  ]);

  const renderMicrosoftClarity = useCallback(() => {
    if (!enableClarity) return null;
    if (rStateIsBot) return null;

    return (
      <script
        id="script-microsoft-clarity"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
					(function(c,l,a,r,i,t,y){
						c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
						t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
						y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
					})(window, document, "clarity", "script", "brl8erkng9");
					`,
        }}
      />
    );
  }, [rStateIsBot]);

  return (
    <Head>
      <title>{title}</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="description" content={description} />
      <meta name="environment" content={environment} />
      <meta
        name="viewport"
        content="width=device-width,minimum-scale=1,initial-scale=1.0, maximum-scale=5.0"
      ></meta>
      <link rel="icon" href={pqImages.favicon} />
      <meta name="theme-color" content={style.pqGreen} />
      <link href={rStateLocationHref?.href?.toLowerCase()} rel="canonical" />

      {pqConfs.country == CountryKeys.IT && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${pqConfs.apple.appId}`}
        />
      )}
      {pqConfs.country == CountryKeys.IT && (
        <meta
          name="google-play-app"
          content={`app-id=${pqConfs.android.appId}`}
        />
      )}

      {loadOneTrust && renderOneTrust()}

      {enableClarity &&
        pqConfs.country == CountryKeys.IT &&
        renderMicrosoftClarity()}

      {children}
    </Head>
  );
};

export default PQHead;
