import React, { memo } from "react";
import style from "./Logo.module.scss";

import Link from "next/link";

import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PQImg from "@/commons/ui/components/pq-img/PQImg";

const Logo = ({}) => {
  const { pqImages, pqConfs } = useSelectorPQGlobalThis();
  const lazyLoad = false;
  return (
    <Link href="/">
      <a className={style.logo}>
        <PQImg
          altText={pqConfs?.site}
          ariaLabel={pqConfs?.site}
          imgStatic={pqImages?.logo}
          lazyLoad={lazyLoad}
        />
      </a>
    </Link>
  );
};
export default memo(Logo);
