import { lazy, Suspense, useCallback, useState } from "react";
import ReactDOM from "react-dom";
import style from "./Layout.module.scss";
import Main from "@/commons/ui/components/main/Main";
import CarouselUtility from "@/commons/utilities/CarouselUtility";
import Footer from "@/commons/ui/components/footer/Footer";
// import RegistrationFloatBell from "@/commons/ui/components/registration-float-bell/RegistrationFloatBell";
import Navbar from "@/commons/ui/components/navbar/Navbar";
import MainSidebarContent from "@/commons/ui/components/main-sidebar-content/MainSidebarContent";
import StartUp from "@/commons/ui/components/start-up/StartUp";
import { useSelector } from "react-redux";
import { ccontextSelector } from "@/commons/redux/selectors/ccontext";

import { ThemeProvider } from "@mui/material/styles";
import themeMuiPromoQui from "@/commons/ui/mui/themeMuiPromoQui";
import AccessComponent from "../access-component";
const Layout = ({ children, breadcrumb }) => {
  const [showSidebarInPosition, setShowSidebar] = useState(null);
  const { rStateHeaders } = useSelector(ccontextSelector);

  const onHamburgerClick = useCallback(
    (position) => setShowSidebar(position),
    [],
  );

  const onSidebarClose = useCallback(() => setShowSidebar(null), []);

  const slidesScrollByDev = useCallback(() => {
    return CarouselUtility.slidesToScrollByDevice(rStateHeaders);
  }, [rStateHeaders]);

  const renderPortalForHomePageCategories = useCallback(() => {
    if (!globalThis.document) return;
    const Sidebar = lazy(
      () =>
        import(
          /* webpackChunkName: "Sidebar" */ "@/commons/ui/components/sidebar/Sidebar"
        ),
    );
    return ReactDOM.createPortal(
      <Suspense fallback={<p>Loading...</p>}>
        <Sidebar
          position={showSidebarInPosition}
          onSidebarClose={onSidebarClose}
        >
          <MainSidebarContent slidesToScroll={slidesScrollByDev()} />
        </Sidebar>
      </Suspense>,
      document.body,
    );
  }, [showSidebarInPosition, onSidebarClose, slidesScrollByDev]);

  return (
    <div className={style.layout}>
      <StartUp>
        <ThemeProvider theme={themeMuiPromoQui}>
          {/* <AccessComponent /> */}
          <Navbar onHamburgerClick={onHamburgerClick} breadcrumb={breadcrumb} />
          <Main>{children}</Main>
          <Footer />
          {/* <RegistrationFloatBell /> */}
          {showSidebarInPosition && renderPortalForHomePageCategories()}
        </ThemeProvider>
      </StartUp>
    </div>
  );
};

export default Layout;
