import { createTheme } from "@mui/material/styles";

const themeMuiPromoQui = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 767,
      lg: 1300,
      xl: 1536,
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#8bc34a",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#42a5f5",
      contrastText: "#ffffff",
    },
    gray: {
      main: "#999",
      contrastText: "#ffffff",
    },
    darkgray: {
      main: "#4a4a4a",
      medium: "#666",
      contrastText: "#ffffff",
    },
    background: {
      default: "#f5f5f5",
    },
    error: {
      main: "#e65100",
    },
    warning: {
      main: "#f9a825",
      contrastText: "#ffffff",
    },
    info: {
      main: "#42a5f5",
      contrastText: "#ffffff",
    },
    success: {
      main: "#8bc34a",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
      disabled: "#dddddd",
      hint: "#dedede",
    },
    pqOrange: "#ef7623",
  },
  typography: {
    button: {
      fontWeight: "700",
    },
  },
};

export default createTheme(themeMuiPromoQui);
