// import ReactPixel from 'react-facebook-pixel';
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
const { pqConfs } = useSelectorPQGlobalThis();

class FacebookPixel {
  constructor(rStateIsBot) {
    this.rStateIsBot = rStateIsBot;
    if (this.isActive()) return;

    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    const ReactPixel = require("react-facebook-pixel");
    this.reactPixel = ReactPixel;
    ReactPixel.init(pqConfs.tracking.fb.pixelId, {}, options);
  }

  isActive() {
    return pqConfs.tracking.fb.pixelId != "" || rStateIsBot;
  }

  pageView() {
    if (this.isActive()) return;
    this.reactPixel.pageView();
  }

  trackCustom(event, data) {
    if (this.isActive()) return;
    this.reactPixel.trackCustom(event, data);
  }

  track(event, data) {
    if (this.isActive()) return;
    this.reactPixel.track(event, data);
  }

  trackLeafletRead(params) {
    this.trackCustom(FaceBookPixelEvent.FB_WEB_EVENT_VIEWER, {
      pq_flyer_id: params.leafletId,
      flyer_id: params.leafletShopFullyId,
      retailer_slug: params.retailerSlug,
      category_slug: params.categorySlug,
    });

    this.track(FaceBookPixelEvent.VIEW_CONTENT, {
      content_type: "product",
      pq_content_ids: [params.leafletId],
      content_ids: [params.leafletShopFullyId],
    });
  }

  trackS2SFlyerOpen(params) {
    this.trackCustom(FaceBookPixelEvent.FB_WEB_EVENT_VIEWER, {
      pq_flyer_id: params.leafletId,
      flyer_id: params.leafletSFId,
      retailer_slug: params.retailerSlug,
      category_slug: params.categorySlug,
    });

    this.track(FaceBookPixelEvent.VIEW_CONTENT, {
      content_type: "product",
      pq_content_ids: [params.leafletId],
      content_ids: [params.leafletSFId],
    });
  }
}

const FaceBookPixelEvent = {
  FB_WEB_EVENT_VIEWER: "FBwebEventViewer",
  VIEW_CONTENT: "ViewContent",
  FLYER_PAGE_BROWSE: "FlyerPageBrowse",
  EXTERNAL_LINK_CLICKED: "ExternalLinkClicked",
  API_V2: "apiV2new",
};

export default FacebookPixel;
export { FaceBookPixelEvent };
