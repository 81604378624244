import locationEvents from "@/commons/redux/events/locationEvents";

const cleanUrlLocation = () => {
  return { type: locationEvents.CLEAN_URL_LOCATION };
};
const setUrlLocation = (position) => {
  return { type: locationEvents.SET_URL_LOCATION, payload: position };
};

const cleanUserLocation = () => {
  return { type: locationEvents.CLEAN_USER_LOCATION };
};
const setUserLocation = (position) => {
  return { type: locationEvents.SET_USER_LOCATION, payload: position };
};

export { cleanUrlLocation, setUrlLocation, cleanUserLocation, setUserLocation };
