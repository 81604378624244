import usePQRoutes from "@/commons/hooks/usePQRoutes";
import { RoutesKeys } from "@/commons/keys";
class ProductUtils {
  static getUrl(categorySlug, productSlug) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.PRODUCT, {
      categoty: `${categorySlug}`,
      product: `${productSlug}`,
    });
  }
}

export default ProductUtils;
