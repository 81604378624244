import React, { useState } from "react";
import style from "./Navbar.module.scss";

import Logo from "@/commons/ui/components/logo/Logo";

import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";

import BreadCrumb from "@/commons/ui/components/breadcrumb/BreadCrumb";
import SidebarConstants from "@/commons/ui/constants/SidebarConstants";
import LocationAutocomplete from "@/commons/ui/components/location-autocomplete/LocationAutocomplete";
import Searchbar from "@/commons/ui/components/searchbar/Searchbar";
import LocationDialog from "@/commons/ui/components/location-dialog/LocationDialog";
import usePQRoutes from "@/commons/hooks/usePQRoutes";
import RoutesKeys from "@/commons/keys/RoutesKeys";
import { useRouter } from "next/router";

const Navbar = ({ onHamburgerClick, breadcrumb }) => {
  const [showLocationDialog, setShoLocationDialog] = useState(false);
  const routes = usePQRoutes();
  const router = useRouter();
  return (
    <nav className={style.navbar}>
      <div className={style.navbarContent}>
        <div className={style.navbarHeader}>
          <div
            className={style.navbarHeaderHamburger}
            onClick={() => onHamburgerClick(SidebarConstants.LEFT)}
          >
            <PQIcon icon={"bars"} />
          </div>
          <div className={style.navbarHeaderLogo}>
            <Logo />
          </div>
          <div className={style.navbarHeaderActions}>
            {/* <div
              className={`${style.navbarHeaderActionsIcon} ${style.navbarHeaderActionsHamburger}`}
              onClick={() =>
                router.push(routes(RoutesKeys.PREFERENCES_REGISTRATION))
              }
            >
              <PQIcon icon={"bell"} />
            </div> */}
            <div
              className={`${style.navbarHeaderActionsIcon} ${style.navbarHeaderActionsHamburger}`}
              onClick={() => setShoLocationDialog(true)}
            >
              <PQIcon icon={"crosshairs"} />
            </div>
            <div
              className={`${style.navbarHeaderActionsIcon} ${style.navbarHeaderActionsHamburger}`}
              onClick={() => onHamburgerClick(SidebarConstants.RIGHT)}
            >
              <PQIcon icon={"bars"} />
            </div>
          </div>
        </div>
        <div className={style.navbarTools}>
          <div className={style.navbarToolsSearch}>
            <Searchbar />
          </div>
          <div className={style.navbarToolsLocation}>
            <LocationAutocomplete />
          </div>
        </div>
        <BreadCrumb sections={breadcrumb} />
      </div>
      {showLocationDialog && (
        <LocationDialog
          onDismissed={(_) => setShoLocationDialog(false)}
          showLocationDialog={showLocationDialog}
        />
      )}
    </nav>
  );
};

Navbar.defaultProps = {
  onHamburgerClick: () => {},
};

export default Navbar;
