import React, { useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./LocationDialog.module.scss";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import LocationAutocomplete from "@/commons/ui/components/location-autocomplete/LocationAutocomplete";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import Button from "@mui/material/Button";

const LocationDialog = ({
  open: openProps,
  onDismissed,
  showLocationDialog,
}) => {
  const { pqImages } = useSelectorPQGlobalThis();
  const t = usePQTranslations();

  const handleClose = () => {
    onDismissed();
  };

  return (
    <Dialog
      classes={{ paper: styles.dialogPaper, container: styles.container }}
      open={showLocationDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="xl"
    >
      <div className={styles.locationDialog}>
        <Button onClick={() => onDismissed()}>
          <PQIcon icon={"x1"} classesAdd={[styles.locationDialogCross]} />
        </Button>
        <div className={styles.locationDialogHeading}>
          <h2>{t("location.improve_position")}</h2>
          <h3>{t("offers.see_offers_near_you")}</h3>
        </div>
        <div
          className={styles.locationDialogBody}
          style={{ backgroundImage: `url(${pqImages.locationDialogMap})` }}
        >
          <LocationAutocomplete vertical={true} />
        </div>
      </div>
    </Dialog>
  );
};

LocationDialog.defaultProps = {
  open: true,
  onDismissed: (_) => null,
  geolocationError: false,
};

export default LocationDialog;
