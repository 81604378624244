import { useSelector } from "react-redux";
import LocationModel from "@/commons/dataset/models/LocationModel";

class LocationUtility {
  static getUserLocation() {
    const { location } = useSelector((state) => state);
    const userLocation = location?.userLocation
      ? new LocationModel(location?.userLocation)
      : null;
    return userLocation;
  }

  static getUrlLocation() {
    const { location } = useSelector((state) => state);
    const urlLocation = location?.urlLocation
      ? new LocationModel(location?.urlLocation)
      : null;
    return urlLocation;
  }

  static getRealLocation() {
    const { location } = useSelector((state) => state);
    const realLocation = location?.realLocation
      ? new LocationModel(location?.realLocation)
      : null;
    return realLocation;
  }

  static isLocalPage() {
    const { location } = useSelector((state) => state);
    const urlLocation = location?.urlLocation
      ? new LocationModel(location?.urlLocation)
      : null;
    return urlLocation != null;
  }
}

export default LocationUtility;
