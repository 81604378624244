import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

class GooglePlaces {
  static injectGooglePlacesScript() {
    return new Promise((resolve, reject) => {
      const refScript = document.querySelector(
        "script[data-type='GOOGLE_PLACES']",
      );
      if (!refScript) {
        const { pqConfs } = useSelectorPQGlobalThis();
        const script = document.createElement("script");
        script.dataset.type = "GOOGLE_PLACES";
        script.type = "text/javascript";
        script.async = true;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        script.src = `https://maps.googleapis.com/maps/api/js?key=${pqConfs.google.googleMaps}&libraries=places,geometry&language=${pqConfs.locale}`;
        document.getElementsByTagName("head")[0].appendChild(script);
      } else {
        resolve(true);
      }
    });
  }

  static findPlace(textSearch) {
    return new Promise(async (resolve, reject) => {
      const scriptLoad = await GooglePlaces.injectGooglePlacesScript();
      if (scriptLoad) {
        const { pqConfs } = useSelectorPQGlobalThis();
        const autocompleteService =
          new google.maps.places.AutocompleteService();
        const params = {
          input: textSearch,
          componentRestrictions: { country: pqConfs.country },
          types: ["geocode"],
        };

        autocompleteService.getPlacePredictions(params, (results, status) => {
          if (
            status === google.maps.GeocoderStatus.OK ||
            status === google.maps.GeocoderStatus.ZERO_RESULTS
          ) {
            resolve(results);
          } else {
            reject(status);
          }
        });
      }
    });
  }

  static async placeDetails(location) {
    return new Promise(async (resolve, reject) => {
      const scriptLoad = await GooglePlaces.injectGooglePlacesScript();
      if (scriptLoad) {
        const geocoder = new google.maps.Geocoder();
        const params = { placeId: location.place_id };
        geocoder.geocode(params, (locationDetails, status) => {
          if (
            status === google.maps.GeocoderStatus.OK ||
            status === google.maps.GeocoderStatus.ZERO_RESULTS
          ) {
            resolve(
              GooglePlaces.parseResultPlaceDetails(
                locationDetails[0],
                location,
              ),
            );
          } else {
            reject(status);
          }
        });
      }
    });
  }

  static async placeDetailsByCoords({ location }) {
    return new Promise(async (resolve, reject) => {
      const scriptLoad = await GooglePlaces.injectGooglePlacesScript();
      if (scriptLoad) {
        var latlng = new google.maps.LatLng(location.lat, location.lng);
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: latlng }, function (results, status) {
          if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
          }
          if (status == google.maps.GeocoderStatus.OK) {
            var address = results[0].formatted_address;
            resolve(address);
          } else {
            reject(status);
          }
        });
      }
    });
  }

  static parseResultPlaceDetails(locationDetails, location) {
    let locationParse = {
      latitude: locationDetails.geometry.location.lat(),
      longitude: locationDetails.geometry.location.lng(),
      full_address: `${location.structured_formatting.main_text} - ${location.structured_formatting.secondary_text}`,
      short_address: location.structured_formatting.secondary_text,
    };

    for (let i = 0; i < locationDetails.address_components.length; i++) {
      let addressComponent = locationDetails.address_components[i];
      let { types } = addressComponent;

      //--- SHORT ADDRESS ---
      if (types.indexOf("administrative_area_level_3") >= 0) {
        locationParse.short_address =
          addressComponent.long_name || addressComponent.short_name;
      }
      //--- CAP ---
      else if (types.indexOf("postal_code") >= 0) {
        locationParse.zip_code =
          addressComponent.long_name || addressComponent.short_name;
      }
    }
    return locationParse;
  }
}

export default GooglePlaces;
