import { LeafletTypeConstants } from "@/commons/dataset/constants/LeafletConstats";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

class TrackingUtility {
  static skipTrack(validCountries, rStateIsBot) {
    const { pqConfs } = useSelectorPQGlobalThis();
    if (rStateIsBot || !validCountries.includes(pqConfs.country)) return true;
    return false;
  }

  static getFlyerType(type) {
    if (type == LeafletTypeConstants.LEAFLET)
      return TrackingUtility.CONSTANTS.FLYER_TYPE.FLAYER;
    else if (type == LeafletTypeConstants.CLICK_TO_STORE_LEAFLET)
      return TrackingUtility.CONSTANTS.FLYER_TYPE.CLICK_OUT;
    else if (type == LeafletTypeConstants.S2S)
      return TrackingUtility.CONSTANTS.FLYER_TYPE.S2S;
    else return TrackingUtility.CONSTANTS.FLYER_TYPE.FLAYER;
  }

  static getUTMMFromPage(page) {
    if (["home-page"].includes(page)) {
      return TrackingUtility.CONSTANTS.UTMM.HOME;
    } else if (
      [
        "retailer",
        "offers-retailer-category",
        "store",
        "stores",
        "open-stores-today",
        "shopping-center",
        "shopping-centers-list",
      ].includes(page)
    ) {
      return TrackingUtility.CONSTANTS.UTMM.RETAILER;
    } else if (["preferred-leaflets"].includes(page)) {
      return TrackingUtility.CONSTANTS.UTMM.RETAILER_PREFERED;
    } else if (["loyalty-cards", "loyalty-card"].includes(page)) {
      return TrackingUtility.CONSTANTS.UTMM.LOYALTY_CARD;
    } else if (
      [
        "main-category",
        "offers",
        "search",
        "product-categories",
        "special-prices",
      ].includes(page)
    ) {
      return TrackingUtility.CONSTANTS.UTMM.CATEGORY;
    } else if (["leaflet_viewer"].includes(page)) {
      return TrackingUtility.CONSTANTS.UTMM.SIMILAR_LEAFLET;
    } else {
      return TrackingUtility.CONSTANTS.UTMM.HOME;
    }
  }

  static getLocationParams(userLocation) {
    let locationMode = TrackingUtility.CONSTANTS.LOCATION_MODE.BACKOFF;
    if (!userLocation.default) {
      locationMode =
        userLocation.isFromIP ||
        userLocation.isFromGPS ||
        userLocation.isFromURL
          ? TrackingUtility.CONSTANTS.LOCATION_MODE.RETRIVED
          : TrackingUtility.CONSTANTS.LOCATION_MODE.USER;
    }

    const locationParams = {
      lat: `${userLocation?.latitude}`,
      lng: `${userLocation?.longitude}`,
      lm: locationMode,
      la: 15 /*???SF???*/,
    };

    if (userLocation.default) {
			// if default use Rome
			locationParams.lat = `${userLocation?.latitude}`;
			locationParams.lng = `${userLocation?.longitude}`;
		}
    
    return locationParams;
  }
}

TrackingUtility.CONSTANTS = {
  TYPE: {
    WEB: "w",
    MOBILE: "m",
  },
  UTMS: {
    DIRECT: "direct",
  },
  TEXT: {
    MEMO: "memo",
  },
  ORIGIN: {
    PRODUCT_LIST: "lp",
  },
  UTMM: {
    HOME: "h", //home
    CATEGORY: "c", //vista categoria
    RETAILER: "rd", //vista retailer
    RETAILER_PREFERED: "rp", //vista volantini di un retailer preferito
    LOYALTY_CARD: "lcs", //vista volantini associati a carta fedeltà inserita
    SIMILAR_LEAFLET: "cs", //lista volantini suggeriti al termine del leaflet viewer
  },
  ET: {
    FLYER_GIB_BUTTON: "fgb",
    FLYER_GIB: "fg",
    FLYER_IMPRESSION: "fi",
  },
  FLYER_TYPE: {
    XL_STANDARD: "xls",
    XL_CUSTOM: "xlc",
    DEFAULT: "d",
    FLAYER: "flyer",
    CLICK_OUT: "click-out",
    S2S: "s2s",
  },
  LOCATION_MODE: {
    RETRIVED: "r",
    USER: "u",
    BACKOFF: "b",
  },
  UTM_SOURCE: {
    DEFAULT: "default",
  },
};

export default TrackingUtility;
