import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { styled } from "@mui/material/styles";

const PQAutocomplete = styled(Autocomplete)(({ theme }) => ({
  ".MuiOutlinedInput-root": {
    padding: "3px 0px",
    backgroundColor: "#fff",
    borderRadius: "4px 0 0 4px",
    "&:hover": {
      ".MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  ".MuiFormControl-root": {
    ".MuiInputLabel-root": {
      color: "#999",
    },
  },
}));

const PQAutocompleteVertical = styled(Autocomplete)(({ theme }) => ({
  ".MuiOutlinedInput-root": {
    padding: "3px 0px",
    backgroundColor: "#fff",
    "&:hover": {
      ".MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  ".MuiFormControl-root": {
    ".MuiInputLabel-root": {
      color: "#999",
    },
  },
}));

const PQTextFieldLabelHidden = styled(TextField)(({ theme }) => ({
  "& label.MuiFormLabel-filled": {
    display: "none",
  },
  "& label.Mui-focused": {
    display: "none",
  },
  "& legend": {
    display: "none",
  },
}));

const PQButtonAutocomplete = styled(Button)(({ theme }) => ({
  borderRadius: "0 2px 2px 0",
  boxShadow: "none",

  [theme.breakpoints.down("md")]: {
    backgroundColor: "#fff",
    color: "#8bc34a",
  },
  [theme.breakpoints.up("md")]: {
    backgroundColor: "#8bc34a",
    color: "#fff",
  },
  "&:hover": {
    borderRadius: "0 4px 4px 0",
    boxShadow: "none",
  },
}));

export {
  PQAutocomplete,
  PQButtonAutocomplete,
  PQTextFieldLabelHidden,
  PQAutocompleteVertical,
};
