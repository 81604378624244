import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import Navigator from "@/commons/utilities/Navigator";
import DeviceIdUtility from "@/commons/utilities/DeviceIdUtility";
import BrowserUtility from "@/commons/utilities/BrowserUtility";

class PqTrack {
  static sendTrack(trackObj, rStateIsBot) {
    return new Promise(async (resolve, reject) => {
      const { pqConfs } = useSelectorPQGlobalThis();
      if (rStateIsBot || pqConfs.country !== "it") {
        resolve();
      } else {
        let url = pqConfs.tracking.pq.url;
        const options = {
          //mode: "no-cors",
          method: "POST",
          headers: { "x-api-key": pqConfs.tracking.pq.xApiKey },
          keepalive: true, //garntisce che l'evento termini prima della chiusura
          body: JSON.stringify(trackObj),
        };
        try {
          const result = await fetch(url, options);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }
    });
  }

  static header(eventType, headerParams = {}) {
    const { pqConfs } = useSelectorPQGlobalThis();
    const {
      userLocation,
      rStateLocationHref,
      rStateHeaders,
      rStateDeviceUser,
    } = headerParams;
    const device = DeviceIdUtility.get(rStateDeviceUser);

    const locationHeader = {
      location: null,
      zipCode: null,
      city: null,
      county: null,
    };
    if (!userLocation.default) {
      locationHeader.county = userLocation.county;
      locationHeader.location = `${userLocation.latitude},${userLocation.longitude}`;
      locationHeader.zipCode = userLocation.zipCode;
      locationHeader.city = userLocation.slug;
    }

    let header = {
      ...locationHeader,
      platform: "pq",
      eventType: eventType,
      deviceId: device.id,
      device: device.deviceType,
      deviceType:
        Navigator.isMobile(rStateHeaders) || Navigator.isTablet(rStateHeaders)
          ? "webmobile"
          : "web",
      referral: window.document.referrer,
      appVersion: pqConfs.appVersion,
      browserName: BrowserUtility.getName(),
      browserVersion: BrowserUtility.getVersion(),
      os: BrowserUtility.getOS(),
      origin: rStateLocationHref.href,
    };

    header[eventType] = {};

    return header;
  }

  //-----------------------------------------------
  //                 EVENTI
  //-----------------------------------------------
  static leafletScrollingMode(params) {
    const { rStateIsBot } = params;
    const eventType = "leafletScrollingMode";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      retailerSlug: params.retailerSlug,
      leafletId: params.leafletId,
      switchTo: params.switchTo,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackPartnerLink(params) {
    const { rStateIsBot } = params;
    const eventType = "partnerLink";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      entityType: params.entityType,
      entityId: params.entityId,
      source: params.source,
      destination: params.destination,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackLeafletRead(params) {
    const { rStateIsBot } = params;
    const eventType = "leafletRead";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      retailerSlug: params.retailerSlug,
      leafletId: params.leafletId,
    };
    if (params.scrollingMode)
      trackObj[eventType].scrollingMode = params.scrollingMode;
    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackLeafletPageRead(params) {
    const { rStateIsBot } = params;
    const eventType = "leafletPageRead";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      retailerSlug: params.retailerSlug,
      leafletId: params.leafletId,
      pageNumber: params.pageNumber,
      timeSpent: params.timeSpent,
    };
    if (params.scrollingMode)
      trackObj[eventType].scrollingMode = params.scrollingMode;

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackLeafletLastPageRead(params) {
    const { rStateIsBot } = params;
    const eventType = "leafletLastPageRead";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      retailerSlug: params.retailerSlug,
      leafletId: params.leafletId,
      timeSpent: params.timeSpent,
    };
    if (params.scrollingMode)
      trackObj[eventType].scrollingMode = params.scrollingMode;

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackLeafletReadingTime(params) {
    const { rStateIsBot } = params;
    const eventType = "leafletReadingTime";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      retailerSlug: params.retailerSlug,
      leafletId: params.leafletId,
      timeSpent: params.timeSpent,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackAdvPageImpression(params) {
    const { rStateIsBot } = params;
    const eventType = "advPageImpression";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      name: params.name,
      id: params.id,
      retailerSlug: params.retailerSlug,
      leafletId: params.leafletId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackAdvPageClick(params) {
    const { rStateIsBot } = params;
    const eventType = "advPageClick";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      name: params.name,
      id: params.id,
      retailerSlug: params.retailerSlug,
      leafletId: params.leafletId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackPlayVideo(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sPlayVideo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackViewedVideo(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sViewedVideo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      isSeekVideo: params.isSeekVideo,
      percentage: params.percentage,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  //-----------------------------------------------
  //                 S2S EVENTI
  //-----------------------------------------------
  static trackS2SOfferOpen(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sOfferOpen";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SFlyerOpen(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sLeafletOpen";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      shopfullyRetailerId: params.retailerSFId,
      retailerId: params.retailerId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SViewProductCarousel(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sViewProductCarousel";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      element: params.element,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SViewLastProduct(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sViewLastProduct";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SShowDescription(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sShowDescription";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      element: params.element,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SCallPhone(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sCall";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      storeId: params.storeId,
      shopfullyStoreId: params.storeSFId,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
      to: params.to,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SProductInfo(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sProductInfo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SOpenAllStores(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sOpenAllStores";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SNavigateTo(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sNavigateTo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      storeAddress: params.storeAddress,
      storeId: params.storeId,
      shopfullyStoreId: params.storeSFId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SGoTo(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sGoTo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SEnd(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sEnd";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      retailerId: params.retailerId,
      shopfullyRetailerId: params.retailerSFId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SSwipe(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sSwipe";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      direction: params.direction,
      position: params.position,
      offerTitle: params.offerTitle,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SOfferView(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sOfferView";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SReminder(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sReminder";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      element: params.element,
      position: params.position,
      offerTitle: params.offerTitle,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SOpenRetailerPage(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sOpenRetailerPage";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      shopfullyRetailerId: params.retailerSFId,
      retailerId: params.retailerId,
      retailerSlug: params.retailerSlug,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SCloseDetail(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sCloseDetail";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      position: params.position,
      element: params.element,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SOpenDetail(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sOpenDetail";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      element: params.element,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
      category: params.category,
      origin: params.origin,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SFilterSelected(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sFilterSelected";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      category: params.category,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SHeroSection(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sHeroSection";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SViewAllSimilarProduct(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sViewAllSimilarProduct";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      category: params.category,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SViewProductSimilarProduct(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sViewProductSimilarProduct";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      category: params.category,
      productCategories: params.productCategories,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SStartVideo(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sStartVideo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SViewedVideo(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sViewedVideo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      isSeekVideo: params.isSeekVideo,
      percentage: params.percentage,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }

  static trackS2SToggleView(params = {}) {
    const { rStateIsBot } = params;
    const eventType = "s2sToggleVideo";
    const trackObj = PqTrack.header(eventType, params);

    trackObj[eventType] = {
      leafletId: params.leafletId,
      shopfullyFlyerId: params.leafletSFId,
      leafletTitle: params.leafletTitle,
      position: params.position,
      offerId: params.offerId,
      shopfullyOfferId: params.offerSFId,
      offerTitle: params.offerTitle,
    };

    return PqTrack.sendTrack(trackObj, rStateIsBot);
  }
}

export default PqTrack;
