import Model from "@/commons/dataset/models/Model";
import usePQRoutes from "@/commons/hooks/usePQRoutes";

import { RoutesKeys } from "@/commons/keys";

//--- application ---
/* import { Tracking } from 'Application';
 */
class OfferUtils {
  /* 	static goToPartnerLink(evt, offer) {
		evt.stopPropagation();

		const { partner } = offer;

		if (partner.slug == OfferUtils.PARTNERS.KELKOO || partner.go_to_link) {
			//--- tracking ---
			Tracking.trackPartnerLink({
				entityType: Model.ENTITY.OFFER,
				retailerSlug: offer.retailer.slug,
				categorySlug: offer.category.slug,
				pageNumber: offer.page_number,
				entityId: offer.id,
				source: Tracking.SOURCE.PARTNER_LINK.FROM_POPUP,
				destination: partner.link || offer.url,
			});

			window.open(partner.link || offer.url, '_blank');
		} else {
			if (partner.link) {
				window.open(partner.link, '_blank');
			} else {
				window.location.href = offer.url;
			}
		}
	} */

  static goToCheaperPartnerCategoryLink(
    evt,
    offer,
    rStateLocationHref,
    rStateHeaders,
    rStateDeviceUser,
    rStateAppRender,
  ) {
    evt.stopPropagation();
    const { cheaperPartnerCategory } = offer;

    //--- tracking ---
    Tracking.trackPartnerLink({
      entityType: Model.ENTITY.OFFER,
      retailerSlug: offer.retailer.slug,
      categorySlug: offer.category.slug,
      pageNumber: offer.page_number,
      entityId: offer.id,
      source: Tracking.SOURCE.PARTNER_LINK.FROM_POPUP,
      destination: cheaperPartnerCategory.partnerLink,
      rStateLocationHref: rStateLocationHref,
      rStateHeaders: rStateHeaders,
      rStateDeviceUser: rStateDeviceUser,
      rStateAppRender: rStateAppRender,
      rStateIsBot: rStateIsBot,
    });

    window.open(cheaperPartnerCategory.partnerLink, "_blank");
  }

  static goToOriginalLeaflet(offer) {
    window.open(offer.urlOriginalLeaflet, "_blank");
  }
  static groupingOffersByStore(newResult, oldResult) {
    if (!oldResult) oldResult = {};
    if (!newResult) return oldResult;

    for (let i = 0; i < newResult.length; i++) {
      const result = newResult[i];
      const distance = result.distance;
      const retailer = result.retailer;
      const store = result.store;
      const address =
        !result.standalone && result.store
          ? result.store.composedAddress
          : null;
      const key = `${distance || "standalone"}_${retailer.id}`;

      if (!oldResult[key]) {
        oldResult[key] = {
          distance: distance,
          address: address,
          data: [],
        };
        oldResult[key].retailer = retailer;
        oldResult[key].store = store;
      }
      oldResult[key].data.push(result);
    }

    return oldResult;
  }

  static checkPriceDiff(pOffer, pCheaper) {
    return pOffer + pOffer / 2 >= pCheaper && pOffer - pOffer / 2 <= pCheaper;
  }

  //--- URL ---
  static getUrl(slug) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.OFFER, { slug: `${slug}` });
  }
  /* 
	static getUrlForCity(slug, citySlug) {
		const url = UrlUtils.getUrlFromRoutes(UrlUtils.ROUTES_LOCAL_OFFER, {
			_SLUG_: slug,
			_CITY_: citySlug,
		});
		return url;
	}

	static getUrlForRetailerCategory(retailer, category) {
		const url = UrlUtils.getUrlFromRoutes(UrlUtils.ROUTES_OFFER_RETAILER_CATEGORY, {
			_RETAILER_: retailer,
			_CATEGORY_: category,
		});
		return url;
	}

	static getUrlForCityCategory(city, category) {
		const url = UrlUtils.getUrlFromRoutes(UrlUtils.ROUTES_OFFER_CITY_CATEGORY, {
			_CITY_: city,
			_CATEGORY_: category,
		});
		return url;
	}

	static getUrlForCategory(category) {
		const url = UrlUtils.getUrlFromRoutes(UrlUtils.ROUTES_OFFER_CATEGORY, {
			_CATEGORY_: category,
		});
		return url;
	} */
}

OfferUtils.PARTNERS = {
  KELKOO: "kelkoo",
};

export default OfferUtils;
