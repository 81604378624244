import React, { useMemo } from "react";
import styles from "./AutocompleteItem.module.scss";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import PQImg from "@/commons/ui/components/pq-img/PQImg";
import { ImgModel } from "types/imgModel";

interface Props {
  icon: string;
  image?: ImgModel;
  value?: any;
  blue?: boolean;
  green?: boolean;
  iconClasses?: any;
  imageClasses?: any;
}

const AutocompleteItem = ({
  icon,
  image,
  value,
  blue = false,
  green = false,
  iconClasses = null,
  imageClasses,
}: Props) => {
  const getClasses = useMemo(() => {
    const classes = [styles.autocompleteItem];
    if (blue) classes.push(styles.autocompleteItemBlue);
    if (green) classes.push(styles.autocompleteItemGreen);
    return classes.join(" ");
  }, [blue, green]);

  const getIconClasses = useMemo(() => {
    let classes = [styles.autocompleteItemIcon];
    if (iconClasses) {
      classes = [...classes, iconClasses];
    }
    return classes.join(" ");
  }, [iconClasses]);
  const getImageClasses = useMemo(() => {
    let classes = [styles.autocompleteItemImage];
    if (imageClasses) {
      classes = [...classes, imageClasses];
    }
    return classes.join(" ");
  }, [imageClasses]);

  return (
    <div className={getClasses}>
      {icon && (
        <div className={getIconClasses}>
          <PQIcon icon={icon} />
        </div>
      )}
      {image && (
        <PQImg
          classNameContent={getImageClasses}
          imgModel={image}
          altText={value}
        />
      )}
      {value && <div className={styles.autocompleteItemValue}>{value}</div>}
      <div className={styles.autocompleteItemAfter}>
        <PQIcon icon={"chevron-right"} />
      </div>
    </div>
  );
};

export default AutocompleteItem;
