import React, { useEffect } from "react";
import GTag from "@/commons/tracking/GTag";
import IntersectionLeafletUtility from "@/commons/utilities/IntersectionLeafletUtility";
import EnvUtility from "@/commons/utilities/EnvUtility";
import LocationUtility from "@/commons/utilities/LocationUtility";
import { useSelector } from "react-redux";

const StartUp = ({ children }) => {
  const { ccontext } = useSelector((state) => state);
  const {
    rStateHeaders,
    rStateDeviceUser,
    rStateAppRender,
    rStateIsBot,
    rStateGoogleAnalytics,
    rStateRendertron,
  } = ccontext;

  const userLocation = LocationUtility.getUserLocation();

  useEffect(() => {
    // console.log("@@@@@ googleAnalyticsData:", rStateGoogleAnalytics);

    GTag.init(rStateRendertron, rStateAppRender, rStateGoogleAnalytics);
    !rStateIsBot &&
      EnvUtility.isClientSide &&
      IntersectionLeafletUtility.setProcessBGTrackEvent(
        userLocation,
        rStateHeaders,
        rStateDeviceUser,
        rStateIsBot,
      );

    EnvUtility.isClientSide &&
      window.addEventListener("beforeunload", (ev) => {
        IntersectionLeafletUtility.closeLocalStorageLeaflet();
      });
  }, []);

  return children;
};

export default StartUp;
