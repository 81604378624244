import SettingsQueries from "@/commons/dataset/queries/SettingsQueries";
import merge from "lodash-es/merge";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import Navigator from "@/commons/utilities/Navigator";
import DCIDUtility from "@/commons/utilities/DCIDUtility";
import TrackingUtility from "@/commons/utilities/TrackingUtility";
import PqConsole from "@/commons/utilities/PqConsole";
import CreateTrackingMetaUtility from "@/commons/utilities/CreateTrackingMetaUtility";
import BrowserUtility from "@/commons/utilities/BrowserUtility";

class FlyerImpressionsTrack {
  static settingsData = null;

  static printEvent(trackObj, name, url) {
    const { pqConfs } = useSelectorPQGlobalThis();
    if (pqConfs.env == "staging")
      PqConsole.log(`FLYER IMPRESSION: ${name}`, { url, trackObj });
  }

  static getTrackingUrl() {
    return new Promise(async (resolve, reject) => {
      const { pqConfs } = useSelectorPQGlobalThis();
      if (!FlyerImpressionsTrack.settingsData) {
        let namesSetting =
          pqConfs.env == "staging"
            ? SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_DEV
            : SettingsQueries.REQUEST.PQ_FLYERS_IMPRESSIONS_PROD;

        try {
          const settingsQueries = new SettingsQueries();
          const result = await settingsQueries.getByNames({
            names: namesSetting,
          });
          if (result) {
            FlyerImpressionsTrack.settingsData = result;
            resolve(result);
          } else {
            reject(
              new Error(
                "FlyerImpressionsTrack::getTrackingUrl: no result da settingsQueries.getByNames",
              ),
            );
          }
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(FlyerImpressionsTrack.settingsData);
      }
    });
  }

  static requestHeaderParams(rStateHeaders, rStateDeviceUser) {
    const { pqConfs } = useSelectorPQGlobalThis();
    const headerParams = {
      h: {
        dmh: screen.height.toString(),
        dmw: screen.width.toString(),
        dmd: window.devicePixelRatio.toString(),
        bv: pqConfs.appVersion,
        uid: DCIDUtility.get(rStateDeviceUser),
        pn: `${pqConfs.country}.pq`,
        ct: pqConfs.country.toUpperCase(), //PTI-204
        tp: !Navigator.isWebView()
          ? TrackingUtility.CONSTANTS.TYPE.WEB
          : TrackingUtility.CONSTANTS.TYPE.MOBILE,
        sn: BrowserUtility.getOS(),
        brn: BrowserUtility.getName(),
        brv: BrowserUtility.getVersion(),
        pid: "_pq_",
        dv: null,
        is: null,
        mn: null,
        cuid: null,
        aid: null,
        av: null,
        sv: null,
        cr: null,
      },
      v: "1.0",
    };
    return headerParams;
  }

  static requestCommonParams(userLocation) {
    const commonParams = {
      at: {
        ...TrackingUtility.getLocationParams(userLocation), //lat, lng, lm, la
      },
      ts: new Date().toISOString(),
      ei: DCIDUtility.getRandom(),
    };

    return commonParams;
  }

  static sendTrack(
    trackObj,
    name,
    rStateHeaders,
    rStateDeviceUser,
    rStateIsBot,
  ) {
    return new Promise(async (resolve, reject) => {
      if (
        TrackingUtility.skipTrack(
          FlyerImpressionsTrack.VALID_COUNTRIES,
          rStateIsBot,
        )
      ) {
        resolve();
      } else {
        try {
          const settingsData = await FlyerImpressionsTrack.getTrackingUrl();
          if (settingsData) {
            const trackObjHeader = FlyerImpressionsTrack.requestHeaderParams(
              rStateHeaders,
              rStateDeviceUser,
            );
            const request = { ...trackObjHeader, ...trackObj };
            const options = {
              method: "post",
              keepalive: true, //garntisce che l'evento termini prima della chiusura
              body: JSON.stringify(request),
            };
            const { ts, et, st } = CreateTrackingMetaUtility(
              settingsData.token,
            );
            const url = `${settingsData.url}?ts=${ts}&et=${et}&st=${st}`;
            const result = await fetch(url, options);
            if (result) {
              resolve(result);
              FlyerImpressionsTrack.printEvent(request, name, settingsData.url);
            } else {
              reject(new Error("FlyerImpressionsTrack not work"));
            }
          } else {
            reject(new Error("FlyerImpressionsTrack setting not found"));
          }
        } catch (error) {
          reject(error);
        }
      }
    });
  }

  static trackFlayersImpression(
    leaflets = [],
    userLocation,
    rStateHeaders,
    rStateDeviceUser,
    rStateIsBot,
  ) {
    const events = leaflets.map((leaflet) => {
      const {
        leafletId,
        leafletShopFullyId,
        categoryId,
        order,
        boost,
        timeEnd,
        timeStart,
        page,
        type,
      } = leaflet;

      let event = {
        et: TrackingUtility.CONSTANTS.ET.FLYER_IMPRESSION,
        at: {
          fid: leafletShopFullyId,
          _fid: leafletId,
          cid: null, //vuoto perchè nn abbiamo mappate le category SF
          _cid: categoryId,
          fo: order,
          ft: TrackingUtility.getFlyerType(type),
          utms: TrackingUtility.CONSTANTS.UTM_SOURCE.DEFAULT,
          fw: boost,
          utmm: TrackingUtility.getUTMMFromPage(page),
          ed: timeEnd - timeStart,
        },
      };
      event = merge(
        FlyerImpressionsTrack.requestCommonParams(userLocation),
        event,
      );
      return event;
    });

    const trackObjComplete = { e: events };
    return FlyerImpressionsTrack.sendTrack(
      trackObjComplete,
      "trackFlayersImpression",
      rStateHeaders,
      rStateDeviceUser,
      rStateIsBot,
    );
  }
}

FlyerImpressionsTrack.VALID_COUNTRIES = ["it", "au"];

export default FlyerImpressionsTrack;
