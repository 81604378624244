import React, { memo, useMemo } from "react";
import style from "./MainSidebarContent.module.scss";

import MenuList from "@/commons/ui/components/menu-list/MenuList";
import MenuListItem from "@/commons/ui/components/menu-list-item/MenuListItem";
import MenuListDivider from "@/commons/ui/components/menu-list-divider/MenuListDivider";
// import MyPromoQui from "@/commons/ui/components/my-promoqui/MyPromoQui";

import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";

import usePQTranslations from "@/commons/hooks/usePQTranslations";
import usePQRoutes from "@/commons/hooks/usePQRoutes";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

import { RoutesKeys } from "@/commons/keys";
import { useGetHomeCategoriesQuery } from "generated/graphql/types";
import CategoriesList from "../categories-list/CategoriesList";

const MainSidebarContent = ({ slidesToScroll = 4 }) => {
  const t = usePQTranslations();
  const routes = usePQRoutes();

  const { pqConfs } = useSelectorPQGlobalThis();

  //const { data, loading } = useGetHomeCategoriesQuery();

  const renderGeneralLink = useMemo(() => {
    return (
      <MenuList>
        <MenuListItem
          url={routes(RoutesKeys.HOME_PAGE)}
          label={t("site.all_leaflets")}
          icon={<PQIcon icon={"folder-open"} />}
          color={style.pqBlack}
        />
        <MenuListItem
          url={routes(RoutesKeys.LEAFLET_PREVIEW)}
          label={t("leaflets.new")}
          icon={<PQIcon icon={"newspaper"} />}
          color={style.pqBlue}
        />
        <MenuListDivider />
      </MenuList>
    );
  }, []);

  // const renderMyPromoQui = useMemo(() => {
  //   return <MyPromoQui withCard={false} />;
  // }, []);

  const renderBannerApp = useMemo(() => {
    const { bannerApp } = pqConfs.appStore;
    return (
      bannerApp && (
        <MenuList>
          <MenuListItem
            url="https://promoqui.onelink.me/vPc7/0c5woi3k"
            label={t("site.download_our_app")}
            icon={<PQIcon icon={"mobile-alt"} />}
            color={style.pqBlack}
          />
        </MenuList>
      )
    );
  }, []);

  return (
    <>
      {renderGeneralLink}
      {/* {renderMyPromoQui} */}
      {/* <HPCLink /> */}

      <CategoriesList slidesToScroll={slidesToScroll} carouselModeEnabled />
      {renderBannerApp}
    </>
  );
};

export default memo(MainSidebarContent);
