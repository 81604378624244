import { useSelectorPQGlobalThis } from "../hooks/usePQGlobalThis";

class LocationRewriteUtility {
  static composeRewriteUrl(rStateLocationHref: any, urlLocation: any) {
    console.log("@@@ ### rStateLocationHref.href", rStateLocationHref.href);
    console.log("@@@ ### urlLocation", urlLocation);
    if (!rStateLocationHref || !urlLocation) return null;
    let url = rStateLocationHref.href;
    return url.replace(`${urlLocation.slug}/`, "", "g");
  }

  static reloadOrRewriteUrlToNational(
    appRender: string,
    router: any,
    rStateLocationHref: any,
    urlLocation: any,
  ) {
    const rewriteUrl = LocationRewriteUtility.composeRewriteUrl(
      rStateLocationHref,
      urlLocation,
    );

    // TODO questo use... va rinominato altrimenti viene considerato
    //  da ts come un hook ma non lo è
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pqConfs } = useSelectorPQGlobalThis();

    urlLocation ? router.push(rewriteUrl) : router.reload();
  }
}

export default LocationRewriteUtility;
