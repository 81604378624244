import React, { ReactNode, memo, useMemo } from "react";

import Link from "next/link";
import style from "./MenuListItem.module.scss";

interface Props {
  url: string;
  icon?: ReactNode;
  label?: string;
  color?: string;
  onClick?(): void;
}

const MenuListItem = ({ url, icon, label, color, onClick }: Props) => {
  const itemContent = useMemo(() => {
    return (
      <>
        <span className={style.itemIcon} style={{ color: color }}>
          {icon}
        </span>
        <span className={style.itemLabel}>{label}</span>
      </>
    );
  }, [color, icon, label]);

  return (
    <li className={style.menuListItem}>
      {url && (
        <Link href={url}>
          <a className={style.item} title={label}>
            {itemContent}
          </a>
        </Link>
      )}
      {!url && onClick && (
        <div className={style.item} onClick={() => onClick()}>
          {itemContent}
        </div>
      )}
    </li>
  );
};

export default memo(MenuListItem);
