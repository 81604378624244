import usePQRoutes from "@/commons/hooks/usePQRoutes";
import { RoutesKeys } from "@/commons/keys";

class ShoppingCenterUtils {
  static getUrl(citySlug, sectionSlug, slug) {
    const routes = usePQRoutes();
    return routes(RoutesKeys.SHOPPING_CENTER, {
      city: `${citySlug}`,
      section: `${sectionSlug}`,
      slug: `${slug}`,
    });
  }

  /* 	static getSectionSlug(shoppingCenter) {
		let shoppingCenterSection = t('routes.section_slug_shopping_center_default');
		if (shoppingCenter.info && shoppingCenter.info.section && shoppingCenter.info.section.slug) {
			shoppingCenterSection = shoppingCenter.info.section.slug;
		}

		return shoppingCenterSection;
	} */
}

export default ShoppingCenterUtils;
