import Navigator from "@/commons/utilities/Navigator";

type scrollType = number | undefined;

interface IScrollParams {
  mobileScroll?: scrollType;
  tabletScroll?: scrollType;
  desktopScroll?: scrollType;
}

class CarouselUtility {
  static SLIDES_TO_SCROLL = {
    MOBILE: 2,
    TABLET: 2,
    DESKTOP: 4,
  };

  static slidesToScrollByDevice(
    rStateHeaders: any,
    { mobileScroll, tabletScroll, desktopScroll }: IScrollParams = {},
  ) {
    let slidesToScroll = desktopScroll || this.SLIDES_TO_SCROLL.DESKTOP;

    // NOTA: isTablet sembra sia sempre false
    if (Navigator.isMobile(rStateHeaders)) {
      slidesToScroll = mobileScroll || this.SLIDES_TO_SCROLL.MOBILE;
    } else if (Navigator.isTablet(rStateHeaders)) {
      slidesToScroll = tabletScroll || this.SLIDES_TO_SCROLL.TABLET;
    } else if (Navigator.isDesktop(rStateHeaders)) {
      slidesToScroll = desktopScroll || this.SLIDES_TO_SCROLL.DESKTOP;
    }
    return slidesToScroll;
  }
}

export default CarouselUtility;
