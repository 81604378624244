import { getCookie, setCookie } from "cookies-next";
import { CookieKeys } from "@/commons/keys";
import OneTrustUtils from "@/commons/tracking/OneTrustUtils";
import JSONUtils from "@/commons/utilities/JSONUtils";

class SuggestionsCookieUtils {
  static set(key, entityId) {
    if (
      !OneTrustUtils.getPreferencesUser(OneTrustUtils.KEY.IMPROVED_EXPERIENCE)
    )
      return;

    let suggestionsCookie = getCookie(CookieKeys.SUGGESTIONS) || "{}";
    suggestionsCookie = JSONUtils.parse(suggestionsCookie);
    if (suggestionsCookie) {
      if (suggestionsCookie[key]) {
        const index = suggestionsCookie[key].indexOf(entityId);
        if (index >= 0) {
          suggestionsCookie[key].splice(index, 1);
        }
      } else {
        suggestionsCookie[key] = [];
      }
    } else {
      suggestionsCookie = {};
      suggestionsCookie[key] = [];
    }
    suggestionsCookie[key].push(entityId);
    suggestionsCookie = JSONUtils.stringify(suggestionsCookie);

    console.log("@@@#-- Set Suggestion Cookie:", suggestionsCookie);
    setCookie(CookieKeys.SUGGESTIONS, suggestionsCookie);
  }

  static get() {
    if (
      !OneTrustUtils.getPreferencesUser(OneTrustUtils.KEY.IMPROVED_EXPERIENCE)
    )
      return;

    let suggestionsCookie = getCookie(CookieKeys.SUGGESTIONS) || "{}";
    console.log("@@@#-- Get Suggestion Cookie:", suggestionsCookie);
    suggestionsCookie = JSONUtils.parse(suggestionsCookie);
    return suggestionsCookie;
  }
}

SuggestionsCookieUtils.KEY = {
  CATEGORY: "Category",
  BRAND: "Brand",
  RETAILER: "Retailer",
  PRODUCT: "Product",
};

export default SuggestionsCookieUtils;
