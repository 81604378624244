import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PqConsole from "@/commons/utilities/PqConsole";
import DCIDUtility from "@/commons/utilities/DCIDUtility";
import CreateTrackingMetaUtility from "@/commons/utilities/CreateTrackingMetaUtility";
import TrackingUtility from "@/commons/utilities/TrackingUtility";
import SettingsQueries from "@/commons/dataset/queries/SettingsQueries";
import usePQTranslations from "@/commons/hooks/usePQTranslations";

const t = usePQTranslations();

const createQueryString = (trackObj) => {
  let params = Object.keys(trackObj).map(
    (k) => encodeURIComponent(k) + "=" + encodeURIComponent(trackObj[k]),
  );
  params = params.join("&");
  return params;
};

const getLocationParams = (userLocation) => {
  const locationParams = {
    lat: null,
    lng: null,
    la: 15 /*???SF???*/,
    lm: SFTrack.LOCATION_MODE.BACKOFF,
  };

  const lm =
    userLocation.isFromIP || userLocation.isFromGPS || userLocation.isFromURL
      ? SFTrack.LOCATION_MODE.RETRIVED
      : SFTrack.LOCATION_MODE.USER;
  if (!userLocation.default) {
    locationParams.lat = `${userLocation.latitude}`;
    locationParams.lng = `${userLocation.longitude}`;
    locationParams.lm = lm;
  } else {
    // if default use Rome
    locationParams.lat = `${userLocation.latitude}`;
    locationParams.lng = `${userLocation.longitude}`;
  }

  return locationParams;
};

class SFTrack {
  static settingsData = null;

  static getTrackingParams() {
    return new Promise(async (resolve, reject) => {
      if (!SFTrack.settingsData) {
        const { pqConfs } = useSelectorPQGlobalThis();
        let namesSetting =
          pqConfs.env == "staging"
            ? SettingsQueries.REQUEST.PQ_STAGING
            : SettingsQueries.REQUEST.PQ_PRODUCTION;

        try {
          const settingsQueries = new SettingsQueries();
          const result = await settingsQueries.getByNames({
            names: namesSetting,
          });
          if (result) {
            SFTrack.settingsData = result;
            resolve(result);
          } else {
            reject(
              new Error(
                "SFTrack::getTrackingParams: impossibile caricare settings url e token",
              ),
            );
          }
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(SFTrack.settingsData);
      }
    });
  }

  static printEvent(trackObj, name, url) {
    const { pqConfs } = useSelectorPQGlobalThis();
    if (pqConfs.env == "staging")
      PqConsole.log(`SF TRACK: ${name}`, { url, trackObj });
  }

  static getDCID(rStateDeviceUser) {
    let dcid = DCIDUtility.get(rStateDeviceUser);
    return dcid;
  }

  static requestGetParamsCommon(
    securtyTokenPassword,
    userLocation,
    rStateDeviceUser,
  ) {
    const commonParams = {
      ...CreateTrackingMetaUtility(securtyTokenPassword), //include ts,et,st
      ...getLocationParams(userLocation), //include lat,lng,lm,la
      z: Math.floor(new Date().getTime() / 1000),
      c: t('site.country'),
      t: SFTrack.TYPE.WEB,
      dcid: SFTrack.getDCID(rStateDeviceUser),
    };
    console.log("@@@ commonParam", commonParams)
    return commonParams;
  }

  static sendTrack(
    trackObj,
    userLocation,
    name,
    rStateDeviceUser,
    rStateIsBot,
  ) {
    return new Promise(async (resolve, reject) => {
      if (TrackingUtility.skipTrack(SFTrack.VALID_COUNTRIES, rStateIsBot)) {
        resolve();
      } else {
        try {
          const settings = await SFTrack.getTrackingParams();
          if (settings) {
            const token = settings.token;
            const trackObjCommon = SFTrack.requestGetParamsCommon(
              token,
              userLocation,
              rStateDeviceUser,
            );
            const params = createQueryString({
              ...trackObjCommon,
              ...trackObj,
            });
            const options = {
              method: "get",
              keepalive: true, //garantisce che l'evento termini prima della chiusura
            };
            const result = await fetch(`${settings.url}?${params}`, options);
            if (result) {
              resolve(result);
              SFTrack.printEvent(
                { ...trackObjCommon, ...trackObj },
                name,
                settings.url,
              );
            } else {
              reject(error);
            }
          } else {
            PqConsole.error("SFTrack settings not found");
          }
        } catch (error) {
          PqConsole.error("SFTrack send not work", error);
          reject(error);
        }
      }
    });
  }

  static requestGetParamsCommonS2S(params) {
    const { leafletSFId, leafletTitle } = params;
    const commonParamsS2S = {
      sf_sid: "",
      utm_source: "direct",
      utm_medium: "sl",
      gclid: "",
      fid: leafletSFId,
      utm_campaign: leafletTitle,
      a: SFTrack.ACTION.S2S,
    };
    return commonParamsS2S;
  }

  //--- EVENTS ---
  static trackLeafletRead(params) {
    const {
      leafletShopFullyId,
      pageNumber,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObj = {
      a: SFTrack.ACTION.FLAYER_VIEW,
      fid: leafletShopFullyId,
      fvk: SFTrack.FLAYER_VIEW_KIND.START,
      fp: pageNumber,
      utm_source: "" /*???SF???*/,
      utm_medium: "" /*???SF???*/,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackLeafletRead",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackLeafletPageRead(params) {
    const {
      leafletShopFullyId,
      pageNumber,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObj = {
      a: SFTrack.ACTION.FLAYER_VIEW,
      fid: leafletShopFullyId,
      fp: pageNumber,
      fvk: SFTrack.FLAYER_VIEW_KIND.MOVE,
      utm_source: "" /*???SF???*/,
      utm_medium: "" /*???SF???*/,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackLeafletPageRead",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackLeafletLastPageRead(params) {
    const {
      leafletShopFullyId,
      pageNumber,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObj = {
      a: SFTrack.ACTION.FLAYER_VIEW,
      fid: leafletShopFullyId,
      fp: pageNumber,
      fvk: SFTrack.FLAYER_VIEW_KIND.END,
      utm_source: "" /*???SF???*/,
      utm_medium: "" /*???SF???*/,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackLeafletLastPageRead",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  //--- S2S ---
  static trackS2SOfferOpen(params = {}) {
    const { userLocation, rStateDeviceUser, rStateIsBot } = params;
    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      a: SFTrack.ACTION.WEBFLAYER_OPEN,
      fvk: SFTrack.FLAYER_VIEW_KIND.WEB_OPEN,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SOfferOpen",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SFlyerOpen(params = {}) {
    const { retailerSFId, userLocation, rStateDeviceUser, rStateIsBot } =
      params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.VF,
      s2s_ec: SFTrack.EVENT_CATEGORY.I,
      s2s_ev: retailerSFId,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SFlyerOpen",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SViewProductCarousel(params = {}) {
    const {
      element,
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.VPC,
      s2s_ec: SFTrack.EVENT_CATEGORY.I,
      s2s_el: element,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SViewProductCarousel",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SViewLastProduct(params = {}) {
    const { offerSFId, userLocation, rStateDeviceUser, rStateIsBot } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.VL,
      s2s_ec: SFTrack.EVENT_CATEGORY.I,
      s2s_origin: SFTrack.ORIGIN.PRODUCT,
      s2s_iid: offerSFId,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SViewLastProduct",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SShowDescription(params = {}) {
    const {
      element,
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.SD,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: element,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SShowDescription",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SCallPhone(params = {}) {
    const {
      storeSFId,
      position,
      offerSFId,
      offerTitle,
      to,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.CALL,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_ev: storeSFId,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
      s2s_to: to,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SCallPhone",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SProductInfo(params = {}) {
    const {
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.MI,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
      s2s_el: position,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SProductInfo",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SOpenAllStores(params = {}) {
    const { userLocation, rStateDeviceUser, rStateIsBot } = params;
    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.OAS,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SOpenAllStores",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SNavigateTo(params = {}) {
    const {
      storeAddress,
      storeSFId,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.NT,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: storeAddress, //Address - ZIP - City,
      s2s_ev: storeSFId,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SNavigateTo",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SGoTo(params = {}) {
    const { userLocation, rStateDeviceUser, rStateIsBot } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.GOTO,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: "web",
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SGoTo",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SEnd(params = {}) {
    const {
      leafletTitle,
      retailerSFId,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.END,
      s2s_el: leafletTitle,
      s2s_ev: retailerSFId,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SEnd",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SSwipe(params = {}) {
    const {
      direction,
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ec: SFTrack.EVENT_CATEGORY.S,
      s2s_el: "product",
      s2s_ea:
        direction == "l"
          ? SFTrack.EVENT_ACTION.SWIPE_LEFT
          : SFTrack.EVENT_ACTION.SWIPE_RIGHT,
      s2s_pos: position,
      s2s_title: offerTitle,
      s2s_iid: offerSFId,
    };
    return SFTrack.sendTrack(
      ...trackObj,
      userLocation,
      "trackS2SSwipe",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SOfferView(params = {}) {
    const { leafletSFId, userLocation, rStateDeviceUser, rStateIsBot } = params;
    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      a: SFTrack.ACTION.WEBFLAYER_OPEN,
      fid: leafletSFId,
      fvk: SFTrack.FLAYER_VIEW_KIND.W,
    };

    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SOfferView",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SReminder(params = {}) {
    const {
      element,
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;
    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.REMINDER,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: element,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SReminder",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SOpenRetailerPage(params = {}) {
    const {
      retailerSFId,
      retailerSlug,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.RETAILER,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_ev: retailerSFId,
      s2s_el: retailerSlug,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SOpenRetailerPage",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SCloseDetail(params = {}) {
    const {
      element,
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.CLOSE_DETAILS,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_to: window.location.origin + window.location.pathname,
      s2s_el: element,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SCloseDetail",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SOpenDetail(params = {}) {
    const {
      element,
      position,
      offerSFId,
      offerTitle,
      origin,
      category,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.OPEN_DETAILS,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: element,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
      s2s_origin: origin,
      s2s_pc: category,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SOpenDetail",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SFilterSelected(params = {}) {
    const { category, userLocation, rStateDeviceUser, rStateIsBot } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.FILTER_SELECTED,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: category,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SFilterSelected",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SHeroSection(params = {}) {
    const { userLocation, rStateDeviceUser, rStateIsBot } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.HERO_SECTION,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: "image",
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SHeroSection",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SViewAllSimilarProduct(params = {}) {
    const { category, userLocation, rStateDeviceUser, rStateIsBot } = params;
    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.VIEW_ALL_SIMILAR_PRODUCT,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_cc: category,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SViewAllSimilarProduct",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SViewProductSimilarProduct(params = {}) {
    const {
      category,
      productCategories,
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.VIEW_PRODUCT_SIMILAR_PRODUCT,
      s2s_ec: SFTrack.EVENT_CATEGORY.I,
      s2s_cc: category,
      s2s_pc: productCategories,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SViewProductSimilarProduct",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SStartVideo(params = {}) {
    const {
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.START_VIDEO,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: "card",
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      ...trackObj,
      userLocation,
      "trackS2SStartVideo",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SViewedVideo(params = {}) {
    const {
      isSeekVideo,
      percentage,
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.VIEWED_VIDEO,
      s2s_ec: SFTrack.EVENT_CATEGORY.I,
      s2s_el: isSeekVideo ? "ff" : "noff",
      s2s_ev: percentage,
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SStartVideo",
      rStateDeviceUser,
      rStateIsBot,
    );
  }

  static trackS2SToggleView(params = {}) {
    const {
      position,
      offerSFId,
      offerTitle,
      userLocation,
      rStateDeviceUser,
      rStateIsBot,
    } = params;

    const trackObjCommonS2S = SFTrack.requestGetParamsCommonS2S(params);
    const trackObj = {
      ...trackObjCommonS2S,
      s2s_ea: SFTrack.EVENT_ACTION.TOGGLE_VIDEO,
      s2s_ec: SFTrack.EVENT_CATEGORY.C,
      s2s_el: "full",
      s2s_pos: position,
      s2s_iid: offerSFId,
      s2s_title: offerTitle,
    };
    return SFTrack.sendTrack(
      trackObj,
      userLocation,
      "trackS2SStartVideo",
      rStateDeviceUser,
      rStateIsBot,
    );
  }
}

SFTrack.xApiKey = "qdWGCkHC263El2eoZK02w6NN9owmhsHc35q9SJKl";
SFTrack.SOURCE = {
  PARTNER_LINK: {
    FROM_LEAFLET: "from_leaflet",
    FROM_OFFER_LIST: "from_offer_list",
    FROM_POPUP: "from_popup",
  },
};

SFTrack.ACTION = {
  FLAYER_VIEW: "fv",
  TRIGGER_OPEN: "to",
  TRIGGER_VIEW: "tv",
  WEBFLAYER_OPEN: "ov",
  S2S: "s2s",
};

SFTrack.TYPE = {
  WEB: "w",
  MOBILE: "m",
};

SFTrack.LOCATION_MODE = {
  RETRIVED: "r",
  USER: "u",
  BACKOFF: "b",
};

SFTrack.FLAYER_VIEW_KIND = {
  START: "s",
  MOVE: "m",
  END: "e",
  WEB_OPEN: "wo",
  W: "w",
};

SFTrack.SOURCE = {
  DIRECT: "direct",
  EMAIL: "email",
  WIDGET: "widget",
  FACEBOOK: "facebook",
};

SFTrack.EVENT_ACTION = {
  VF: "vf",
  VPC: "vpc",
  VL: "vl",
  SD: "sd",
  CALL: "c",
  MI: "mi",
  OAS: "oas",
  NT: "nt",
  GOTO: "goto",
  END: "end",
  SWIPE_LEFT: "sl",
  SWIPE_RIGHT: "sr",
  REMINDER: "r",
  RETAILER: "orp",
  CLOSE_DETAILS: "cd",
  OPEN_DETAILS: "od",
  FILTER_SELECTED: "fs",
  HERO_SECTION: "hs",
  VIEW_ALL_SIMILAR_PRODUCT: "vasp",
  VIEW_PRODUCT_SIMILAR_PRODUCT: "vpsp",
  START_VIDEO: "sv",
  VIEWED_VIDEO: "vv",
  PLAY_VIDEO: "pv",
  TOGGLE_VIDEO: "tv",
};

SFTrack.EVENT_CATEGORY = {
  I: "i",
  C: "c",
  S: "s",
};

SFTrack.ORIGIN = {
  PRODUCT: "product",
  LANDING: "landing",
};

SFTrack.TO = {
  LANDING_HEADER: "landing-header",
  PRODUCT_STORE: "product-store",
  PRODUCT_HEADER: "product-header",
  PRODUCT_DETAIL: "product-detail",
};

SFTrack.VALID_COUNTRIES = ["it", "au"];

export default SFTrack;
