import React, { memo } from "react";
import style from "./MenuList.module.scss";

const MenuList = ({ children }) => {
  return <ul className={style.menuList}>{children}</ul>;
};

export default memo(MenuList);

// import styles from '../styles/MenuList.scss';

// class MenuList extends PureComponent {
//   render() {
//     const { items, onClick, children } = this.props;

//     return (
//       <ul className="menu-list">
//         {children}
//       </ul>
//     )
//   }
// }

// MenuList.defaultProps = {
//   items: [],
//   onClick: _ => {}
// }

// const MenuListDivider = _ => {
//   return <li className={`${styles.menuListItem} ${styles.menuListItemDivider}`}><hr/></li>
// };

// const MenuListItem = ({ url, icon, label, color, onClick }) => {
//   const itemContent = (
//     <Fragment>
//       <span className={styles.itemIcon} style={{ color: color }}>
//         {icon}
//       </span>
//       <span className={styles.itemLabel}>
//         {label}
//       </span>
//     </Fragment>
//   );

//   if(url) {
//     return (
//       <li className={styles.menuListItem}>
//         <a href={url} className={styles.item} alt={label}>{itemContent}</a>
//       </li>
//     );
//   }

//   return (
//     <li className={styles.menuListItem}>
//       <div className={styles.item} onClick={_ => onClick()}>
//         {itemContent}
//       </div>
//     </li>
//   );
// };

// MenuListItem.defaultProps = {
//   onClick: _ => {}
// }

// export { MenuListItem, MenuListDivider };
// export default MenuList;
