import Model from "./Model";
import ImageModel from "./ImageModel";

class SuggestionModel extends Model {
  constructor(suggestion = {}) {
    super(suggestion);

    this.type = suggestion.type;
    this.name = suggestion.name;
    this.icon = suggestion.icon ? new ImageModel(suggestion.icon) : null;
    this.retailerSectionSlug = suggestion.retailerSectionSlug;
    this.retailerSlug = suggestion.retailerSlug;
    this.slug = suggestion.slug;
    this.sectionSlug = suggestion.sectionSlug;
    this.itemName = suggestion.itemName;
    this.categorySlug = suggestion.categorySlug;
    this.citySlug = suggestion.citySlug;
  }

  static getCollection(suggestions = []) {
    return suggestions.map((suggestion) => new SuggestionModel(suggestion));
  }
}

export default SuggestionModel;
