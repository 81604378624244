import { detect } from "detect-browser";

class BrowserUtility {
  static getName() {
    const browser = detect();
    let browserName = "other";
    if (browser) {
      browserName = browser.name;
      if (browserName == "ios") {
        browserName = "safari";
      }
    }
    return browserName;
  }

  static getVersion() {
    const browser = detect();
    if (browser) {
      return browser.version;
    }
    return "n.v.";
  }

  static getOS() {
    const browser = detect();
    if (browser) {
      return browser.os;
    }
    return "n.v.";
  }
}

export default BrowserUtility;
