import { CSSProperties } from "react";
import style from "./PQIcon.module.scss";

const getClasses = (
  icon: string,
  rotate: boolean,
  classesAdd: string[],
): string => {
  if (icon.indexOf("fa-") == 0) icon = icon.replace("fa-", "icon-");
  else if (icon.indexOf("icon-") < 0) icon = `icon-${icon}`;

  icon = icon.replace("fa-", "icon-");
  const classes = [...classesAdd];
  if (rotate) classes.push(style.pqIconRotate);
  return [style.pqIcon, style[icon], ...classes].join(" ");
};

interface Props {
  icon: string;
  stylesAdd?: CSSProperties;
  classesAdd?: string[];
  rotate?: boolean;
}

const PQIcon = ({ icon, stylesAdd, classesAdd = [], rotate }: Props) => {
  const classes = getClasses(icon, rotate, classesAdd);

  return <i className={classes} style={stylesAdd}></i>;
};

export default PQIcon;
