import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

class CityUtils {
  static isSmallCity(city) {
    const { pqConfs } = useSelectorPQGlobalThis();
    return city.storeCount <= pqConfs?.threshold?.store?.small || 0;
  }

  static isMediumCity(city) {
    const { pqConfs } = useSelectorPQGlobalThis();
    return (
      (!CityUtils.isSmallCity(city) &&
        city.storeCount <= pqConfs?.threshold?.store?.medium) ||
      0
    );
  }

  static isLargeCity(city) {
    return !CityUtils.isSmallCity(city) && !CityUtils.isMediumCity(city);
  }
}

export default CityUtils;
