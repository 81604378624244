import Queries from "./Queries";
import SuggestionModel from "@/commons/dataset/models/SuggestionModel";

class SuggestionsQueries extends Queries {
  getResult(result, returnJson = false) {
    if (result.data) {
      const dataResult = result.data;
      if (dataResult.Suggestions) {
        if (returnJson) {
          return dataResult.Suggestions;
        } else {
          const suggestions = SuggestionModel.getCollection(
            dataResult.Suggestions,
          );

          return suggestions;
        }
      } else if (dataResult.Suggestions) {
        if (returnJson) {
          return dataResult.Suggestions;
        } else {
          const suggestions = new SuggestionModel(dataResult.Suggestions);
          return suggestions;
        }
      }
    }
    return null;
  }

  async searchSuggestions(params) {
    const defaults = {
      text: null,
      searchable: [
        // "shopping_centers",
        "offers",
        "retailers",
        "synonyms",
        "categories",
        "products",
        "brands",
      ],
      retailerIds: [],
      brandIds: [],
      categoryIds: [],
    };
    params = Object.assign(defaults, params);
    const { text, searchable, retailerIds, brandIds, categoryIds } = params;

    const name = "SuggestionsQueries_searchSuggestions";
    const data = `id type name
        icon retailerSectionSlug retailerSlug slug sectionSlug
        itemName categorySlug citySlug images_path`;
    const query = `Suggestions(
      text: "${text}", 
      searchable: [${searchable.join(", ")}], 
      ${
        retailerIds.length > 0
          ? `retailer_ids:[${retailerIds.join(", ")}],`
          : ""
      }
      ${brandIds.length > 0 ? `brand_ids:[${brandIds.join(", ")}],` : ""}
      ${
        categoryIds.length > 0
          ? `category_ids:[${categoryIds.join(", ")}],`
          : ""
      }
    )`;

    return this.execQuery(name, query, data, [], null, false);
  }

  async initialCategorySuggestions(params) {
    const defaults = {
      searchable: ["categories"],
      categoryIds: SuggestionsQueries.INITAL_SUGGESTION_CATEGORY,
    };
    params = Object.assign(defaults, params);
    const { searchable, categoryIds } = params;

    const name =
      "SuggestionsQueries_initialCategorySuggestions($categoryIds: [Int],$searchable: [searchableModels])";
    const data = `
      id type name
      icon retailerSectionSlug retailerSlug slug sectionSlug
      itemName categorySlug citySlug images_path`;

    const query = `Suggestions(
      searchable: $searchable, 
      category_ids: $categoryIds
    )`;

    const variables = { searchable, categoryIds };

    return this.execQuery(name, query, data, [], variables);
  }
}

//che il DIO mi perdoni....ma è una porcata RICHIESTA....almeno non è stato messo l'array di categorie cablato nel codice
SuggestionsQueries.INITAL_SUGGESTION_CATEGORY = [
  14754, 14520, 14505, 14939, 14864, 14535, 15229, 14563, 14633, 14397,
];
export default SuggestionsQueries;
