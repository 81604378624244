import React, { memo, useCallback, useMemo } from "react";
import style from "./Footer.module.scss";

import CountryKeys from "@/commons/keys/CountryKeys";

import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import Link from "next/link";

import usePQRoutes from "@/commons/hooks/usePQRoutes";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import { RoutesKeys } from "@/commons/keys";

import PQImg from "@/commons/ui/components/pq-img/PQImg";

import CityUtils from "@/commons/dataset/utils/CityUtils";

import { useSelector } from "react-redux";
import LocationModel from "@/commons/dataset/models/LocationModel";

const Footer = () => {
  const t = usePQTranslations();
  const routes: any = usePQRoutes();
  const { location }: any = useSelector((state) => state);
  const urlLocation = location?.urlLocation
    ? new LocationModel(location?.urlLocation)
    : null;
  const userLocation = location?.userLocation
    ? new LocationModel(location?.userLocation)
    : null;

  const { pqConfs, pqImages } = useSelectorPQGlobalThis();

  const isIT = useMemo(() => {
    return pqConfs?.country === CountryKeys.IT;
  }, [pqConfs]);

  const isAU = useMemo(() => {
    return pqConfs?.country === CountryKeys.AU;
  }, [pqConfs]);

  const isRO = useMemo(() => {
    return pqConfs?.country === CountryKeys.RO;
  }, [pqConfs]);

  const renderColumn1 = useMemo(() => {
    const lazyLoad = false;
    const footerConf = pqConfs?.footer;
    if (footerConf?.columns && !footerConf.columns.col1) return;
    return (
      <div className={style.footerBodyColumn}>
        <div className={style.footerLogo}>
          <PQImg
            imgStatic={pqImages?.logoWhite}
            lazyLoad={lazyLoad}
            altText="logo PromoQui"
          />
        </div>
        <div className={style.footerSocials}>
          <div className={style.social}>
            <Link href={pqConfs?.socialLinks.facebook}>
              <a title="Facebook" target="_blank">
                <PQIcon icon={"facebook-f"} />
              </a>
            </Link>
          </div>
          {pqConfs?.country === CountryKeys.IT && (
            <div className={style.social}>
              <Link href={pqConfs.socialLinks.twitter}>
                <a title="Twitter" target="_blank">
                  <PQIcon icon={"twitter"} />
                </a>
              </Link>
            </div>
          )}
          {pqConfs?.country === CountryKeys.IT && (
            <div className={style.social}>
              <Link href={pqConfs.socialLinks.pinterest}>
                <a title="Pinterest" target="_blank">
                  <PQIcon icon={"pinterest-p"} />
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    pqConfs?.country,
    pqConfs?.footer,
    pqConfs?.socialLinks.facebook,
    pqConfs?.socialLinks.pinterest,
    pqConfs?.socialLinks.twitter,
    pqImages?.logoWhite,
  ]);

  const renderColumn2 = useMemo(() => {
    const { links, columns } = pqConfs?.footer ?? {
      links: null,
      columns: null,
    };
    if (!columns?.col2) return;
    return (
      <div className={style.footerBodyColumn}>
        <div className={style.footerLinksTitle}>{t("site.are_you_user")}</div>
        <ul className={style.footerLinksList}>
          {links.who.active && (
            <li>
              <Link href={routes(RoutesKeys.WHO)}>
                <a title={t("site.who_we_are") as string}>
                  {t("site.who_we_are")}
                </a>
              </Link>
            </li>
          )}
          {links.how.active && (
            <li>
              <Link href={routes(RoutesKeys.HOW)}>
                <a title={t("site.how_it_works")}>{t("site.how_it_works")}</a>
              </Link>
            </li>
          )}
          {pqConfs.appStore.bannerApp && (
            <li>
              <Link href="https://promoqui.onelink.me/vPc7/0c5woi3k">
                <a title={t("site.download_app")}>{t("site.download_app")}</a>
              </Link>
            </li>
          )}
          {links.contactUs.active && (
            <li>
              <Link href={pqConfs.socialLinks.facebook}>
                <a title={t("site.contact_us")} target="_blank">
                  {t("site.contact_us")}
                </a>
              </Link>
            </li>
          )}
          {links.blog.active && (
            <li>
              <Link href={routes(RoutesKeys.BLOG)}>
                <a title={t("site.blog")}>{t("site.blog")}</a>
              </Link>
            </li>
          )}
          {links.eco.active && (
            <li>
              <Link href={routes(RoutesKeys.ECO)}>
                <a title={t("site.eco")}>{t("site.eco")}</a>
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }, [
    pqConfs?.appStore.bannerApp,
    pqConfs?.footer,
    pqConfs?.socialLinks.facebook,
    routes,
    t,
  ]);

  const renderColumn3 = useMemo(() => {
    const { links, columns } = pqConfs?.footer ?? {
      links: null,
      columns: null,
    };
    if (!columns?.col3) return;
    if (
      !links.corporate.link &&
      !links.whoWeAre.active &&
      !links.workingWithUs.active &&
      !links.corporateContactUs.active
    )
      return;
    return (
      <div className={style.footerBodyColumn}>
        <div className={style.footerLinksTitle}>
          {t("site.are_you_company")}
        </div>
        <ul className={style.footerLinksList}>
          {links.corporate.active && (
            <li>
              <Link href={links.corporate.link}>
                <a title={t("site.corporate")} target="_blank">
                  {t("site.corporate")}
                </a>
              </Link>
            </li>
          )}
          {links.whoWeAre.active && (
            <li>
              <Link href={links.whoWeAre.link}>
                <a title={t("site.corporate_who_we_are")} target="_blank">
                  {t("site.corporate_who_we_are")}
                </a>
              </Link>
            </li>
          )}
          {links.workingWithUs.active && (
            <li>
              <Link href={links.workingWithUs.link}>
                <a title={t("site.corporate_working_with_us")} target="_blank">
                  {t("site.corporate_working_with_us")}
                </a>
              </Link>
            </li>
          )}
          {links.corporateContactUs.active && (
            <li>
              <Link href={links.corporateContactUs.link}>
                <a title={t("site.corporate_contact_us")} target="_blank">
                  {t("site.corporate_contact_us")}
                </a>
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }, [pqConfs?.footer, t]);

  const renderColumn4Local = useCallback(() => {
    const { links, columns } = pqConfs.footer;
    if (!columns.col4Local) return;

    let categoriesRoute;

    if (
      urlLocation &&
      urlLocation.slug &&
      !userLocation?.default &&
      CityUtils.isSmallCity(urlLocation.city)
    ) {
      categoriesRoute = null; // routes(RoutesKeys.ALL_CATEGORIES); // NO link for small cities
    } else {
      categoriesRoute = routes(RoutesKeys.ALL_CATEGORIES_LOCAL, {
        city: urlLocation.slug,
      });
    }

    return (
      <div className={style.footerBodyColumn}>
        <div className={style.footerLinksTitle}>{t("site.index")}</div>
        <ul className={style.footerLinksList}>
          {links.allBrands.activeLocal && (
            <li>
              <Link href={routes(RoutesKeys.ALL_BRANDS)}>
                <a title={t("site.all_brands")}>{t("site.all_brands")}</a>
              </Link>
            </li>
          )}
          {links.allCategories.activeLocal && categoriesRoute && (
            <li>
              <Link href={categoriesRoute}>
                <a title={t("site.all_categories")}>
                  {t("site.all_categories")}
                </a>
              </Link>
            </li>
          )}

          {links.allOpenStores.activeLocal &&
            isIT &&
            CityUtils.isLargeCity(urlLocation.city) &&
            !userLocation?.default && (
              <li>
                <Link
                  href={routes(RoutesKeys.ALL_OPEN_STORES_LOCAL, {
                    city: urlLocation.slug,
                  })}
                >
                  <a title={t("site.all_open_stores")} target="_blank">
                    {t("site.all_open_stores")}
                  </a>
                </Link>
              </li>
            )}
          {links.allStores.activeLocal && (
            <li>
              <Link
                href={routes(RoutesKeys.ALL_RETAILERS_LOCAL, {
                  city: urlLocation.slug,
                })}
              >
                <a title={t("site.all_retailers")}>{t("site.all_retailers")}</a>
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  }, [
    isIT,
    pqConfs?.footer,
    routes,
    t,
    urlLocation?.city,
    urlLocation?.slug,
    userLocation?.default,
  ]);

  const renderColumn4National = useCallback(() => {
    const { links, columns } = pqConfs?.footer ?? {
      links: null,
      columns: null,
    };
    if (!columns?.col4National) return;
    return (
      <div className={style.footerBodyColumn}>
        <div className={style.footerLinksTitle}>{t("site.index")}</div>
        <ul className={style.footerLinksList}>
          {links.allBrands.activeNational && (
            <li>
              <Link href={routes(RoutesKeys.ALL_BRANDS)}>
                <a title={t("site.all_brands")}>{t("site.all_brands")}</a>
              </Link>
            </li>
          )}
          {links.allRetailers.activeNational && (
            <li>
              <Link href={routes(RoutesKeys.ALL_RETAILERS)}>
                <a title={t("site.all_retailers")}>{t("site.all_retailers")}</a>
              </Link>
            </li>
          )}
          {links.allCategories.activeNational && (
            <li>
              <Link href={routes(RoutesKeys.ALL_CATEGORIES)}>
                <a title={t("site.all_categories")}>
                  {t("site.all_categories")}
                </a>
              </Link>
            </li>
          )}

          {(isIT || isRO) && links.allLoyaltyCards.activeNational && (
            <li>
              <Link href={routes(RoutesKeys.ALL_LOYALTY_CARDS)}>
                <a title={t("site.all_cards")}>{t("site.all_cards")}</a>
              </Link>
            </li>
          )}
          {links.allCities.activeNational && (
            <li>
              <Link href={routes(RoutesKeys.ALL_CITIES)}>
                <a title={t("site.all_cities")}>{t("site.all_cities")}</a>
              </Link>
            </li>
          )}

          {isIT &&
            links.allOpenStores.activeNational &&
            !userLocation?.default && (
              <li>
                <Link href={routes(RoutesKeys.ALL_OPEN_STORES)}>
                  <a title={t("site.all_open_stores")} target="_blank">
                    {t("site.all_open_stores")}
                  </a>
                </Link>
              </li>
            )}
          {/* {isIT && links.allShoppingCenters.activeNational && (
            <li>
              <Link href={routes(RoutesKeys.ALL_SHOPPING_CENTERS)}>
                <a title={t("site.all_shopping_centers")}>
                  {t("site.all_shopping_centers")}
                </a>
              </Link>
            </li>
          )} */}
        </ul>
      </div>
    );
  }, [isIT, isRO, pqConfs?.footer, routes, t, userLocation?.default]);

  const renderColumn4 = useCallback(() => {
    if (urlLocation?.id) return renderColumn4Local();
    else return renderColumn4National();
  }, [renderColumn4Local, renderColumn4National, urlLocation?.id]);

  const renderLegal = useMemo(() => {
    return (
      <div className={style.footerLegal}>
        <div>{t("site.copyright")}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div style="text-align: center; margin: 0; padding: 0; line-height: 1.2;">${t(
              "site.vat",
            )}</div>`,
          }}
        />
        <p>
          <Link href={routes(RoutesKeys.TERMS_AND_CONDITIONS)}>
            <a title={t("site.terms_and_conditions")} target="_blank">
              {t("site.terms_and_conditions")}
            </a>
          </Link>
          &nbsp;&bull;&nbsp;
          <Link href={pqConfs?.onetrust.privacyPolicyURL}>
            <a title={t("site.privacy")} target="_blank">
              {t("site.privacy")}
            </a>
          </Link>
          &nbsp;&bull;&nbsp;
          <Link href={pqConfs?.onetrust.cookiePolicyURL}>
            <a title={t("site.cookie_policy")} target="_blank">
              {t("site.cookie_policy")}
            </a>
          </Link>
          &nbsp;&bull;&nbsp;
          <style>
            {`
								.ot-sdk-show-settings:hover {
									background: none !important;
									text-decoration: underline;
								}
							`}
          </style>
          <button
            style={{
              background: "none",
              border: "none",
              color: "white",
              padding: "0",
              fontSize: "inherit",
              fontWeight: "600",
            }}
            id="ot-sdk-btn"
            className="ot-sdk-show-settings"
          >
            {t("site.cookie_manage")}
          </button>
          {!isAU && (
            <>
              &nbsp;&bull;&nbsp;
              <Link href={routes(RoutesKeys.REQUEST_PERSONAL_DATA)}>
                <a title={t("site.request_personal_data")} target={"_blank"}>
                  {t("site.request_personal_data")}
                </a>
              </Link>
            </>
          )}
        </p>
        <p>
          {/* INTERNATIONAL:&nbsp;
          <Link href="https://www.promoqui.it">
            <a title="PromoQui">{t("countries.it")}</a>
          </Link> */}
          {/* &nbsp;&bull;&nbsp;
          <Link href="https://www.aicipromotii.ro">
            <a title="AiciiPromotii">{t("countries.ro")}</a>
          </Link>
          &nbsp;&bull;&nbsp;
          <Link href="https://www.ilikesales.co.uk">
            <a title="iLikeSales">{t("countries.uk")}</a>
          </Link> */}
          {/* 					&nbsp;&bull;&nbsp;
					<Link href='https://www.ilikesales.com.au'>
						<a title='iLikeSales'>{t('countries.au')}</a>
					</Link>
					&nbsp;&bull;&nbsp;
					<Link href='https://www.ilikesales.es'>
						<a title='iLikeSales'>{t('countries.es')}</a>
					</Link> */}
        </p>
        <p>{`ver.: 3.0`}</p>
      </div>
    );
  }, [isAU, pqConfs?.onetrust.cookiePolicyURL, routes, t]);

  return (
    <footer className={style.footer} role="contentinfo">
      <div className={style.footerBody}>
        {renderColumn1}
        {renderColumn2}
        {renderColumn3}
        {renderColumn4()}
      </div>
      {renderLegal}
    </footer>
  );
};
export default memo(Footer);
